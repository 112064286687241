import React from 'react'
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Fonts from '../../styles/Fonts';
import FinishedCheck from '../../icons/FinishedCheck';


const CompletedPolicyContainer = styled.div`

`;
const CompletedPolicyCotent = styled.div`
    background-color: #fff;
    margin-right: 100px;
    padding-left: 50px;
    margin-bottom: 80px;
`;
const CompletedPolicyNumber = styled.h1`
    font-size: 18px;
    color: ${Colors.TitleBlue};
    margin: unset;
    font-family: ${Fonts.FiraGOMtavruliBold};
    font-weight: 400;
    margin-top: 50px;
`;
const CompletedGrayLine = styled.span`
    width: 100%;
    height: 1px;
    background-color: #F2F5F7;
    display: block;
    margin: 30px 0px;
`;
const CompletedStatusDiv = styled.div`

`;
const CompletedStatusName = styled.h1`
    font-size: 16px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: ${Colors.TitleBlue};
    margin: unset;
    margin-right: 170px;
`;
const CompletedStatusAnswer = styled.h1`
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.Gray};
    margin: unset;
    font-weight: 400;
`;
const CompletedPolicyInfo = styled.div`
    display: flex;
    align-items: center;
`;
const CompletedPolicyInfoLeft = styled.div`
    display: flex;
    flex-direction: column;
`;
const CompletedPolicyInfoRight = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 50px;
`;
const CompletedPolicyLeftText = styled.h1`
    font-size: 16px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: ${Colors.TitleBlue};
    margin: unset;
    margin: 15px 0px;
`;
const CompletedPolicyRightText = styled.h1`
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.Gray};
    margin: unset;
    font-weight: 400;
    margin: 15px 0px;
`;
const CompletedPolicyFinanceDiv = styled.div`
    display: flex;
    align-items: center;
`;
const CompletedPolicyFinanceLeftDiv = styled.div`
    display: flex;
    flex-direction: column;
`;
const CompletedPolicyFinanceRightDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 50px;
`;
const CompletedPolicyFinancedLeftText = styled.div`
    font-size: 16px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: ${Colors.TitleBlue};
    margin: unset;
    margin: 15px 0px;
`;
const CompletedPolicyFinancedRightText = styled.div`
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.Gray};
    margin: unset;
    font-weight: 400;
    margin: 15px 0px;
`;
const CompletedPolicyUpLoadTextTitle = styled.h1`
    font-size: 18px;
    color: ${Colors.TitleBlue};
    margin: unset;
    font-family: ${Fonts.FiraGOMtavruliBold};
    font-weight: 400;
`;
const CompletedPolicyUpLoadFilesText = styled.h1`
    font-size: 16px;
    color: ${Colors.TitleBlue};
    margin: unset;
    font-family: ${Fonts.FiraGOMtavruliBold};
    font-weight: 400;
    display: block;
    margin-top: 35px;
`;
const Flake = styled.span`
    color: ${Colors.Primary};
`;
const CompletedPolicyUoloadDocuments = styled.h1`
    font-size: 17px;
    color: ${Colors.Gray};
    margin: unset;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    display: block;
    margin-top: 20px;
`;
const ForBottomPadding = styled.div`
    padding-bottom: 80px;
`;
const FinishedCheckIcon = styled(FinishedCheck)`
    height: 10px;
    margin-right: 10px;
    margin-top: 3px;
`;



function CompletedPolicy() {
    return (
        <CompletedPolicyContainer>
            <CompletedPolicyCotent>

                <CompletedPolicyNumber>საგარანტიო # 223635</CompletedPolicyNumber>

                <CompletedGrayLine />

                <CompletedStatusDiv>
                    <CompletedStatusName>სტატუსი</CompletedStatusName>
                    <CompletedStatusAnswer><FinishedCheckIcon />დასრულებული</CompletedStatusAnswer>
                </CompletedStatusDiv>

                <CompletedGrayLine />

                <CompletedPolicyInfo>
                    <CompletedPolicyInfoLeft>
                        <CompletedPolicyLeftText>პოლისი</CompletedPolicyLeftText>
                        <CompletedPolicyLeftText>დაზღვეული</CompletedPolicyLeftText>
                        <CompletedPolicyLeftText>ატვირთვის თარიღი</CompletedPolicyLeftText>
                    </CompletedPolicyInfoLeft>

                    <CompletedPolicyInfoRight>
                        <CompletedPolicyRightText>ჯანმრთელობა</CompletedPolicyRightText>
                        <CompletedPolicyRightText>მარიამ გაფრინდაშვილი</CompletedPolicyRightText>
                        <CompletedPolicyRightText>07 სექტემბერი 2020</CompletedPolicyRightText>
                    </CompletedPolicyInfoRight>
                </CompletedPolicyInfo>

                <CompletedGrayLine />

                <CompletedPolicyFinanceDiv>
                    <CompletedPolicyFinanceLeftDiv>
                        <CompletedPolicyFinancedLeftText>მოთხოვნილი თანხა</CompletedPolicyFinancedLeftText>
                        <CompletedPolicyFinancedLeftText>დაფინანსდა</CompletedPolicyFinancedLeftText>
                    </CompletedPolicyFinanceLeftDiv>
                    <CompletedPolicyFinanceRightDiv>
                        <CompletedPolicyFinancedRightText>1 500 ლარი</CompletedPolicyFinancedRightText>
                        <CompletedPolicyFinancedRightText style={{ color: '#44C2D3' }}>1 000 ლარი</CompletedPolicyFinancedRightText>
                    </CompletedPolicyFinanceRightDiv>
                </CompletedPolicyFinanceDiv>

                <CompletedGrayLine />

                <CompletedPolicyUpLoadTextTitle>ატვირთული დოკუმენტაცია</CompletedPolicyUpLoadTextTitle>

                <CompletedPolicyUpLoadFilesText><Flake>*</Flake> დაწესებულების ბეჭდით, შტამპითა და ხელმოწერით დამოწმებული ფ №IV-100ა</CompletedPolicyUpLoadFilesText>
                    <CompletedPolicyUoloadDocuments>document1.xlx</CompletedPolicyUoloadDocuments>
                    <CompletedPolicyUoloadDocuments>document1.xlx</CompletedPolicyUoloadDocuments>

                <CompletedPolicyUpLoadFilesText><Flake>*</Flake> დეტალური კალკულაცია</CompletedPolicyUpLoadFilesText>
                    <CompletedPolicyUoloadDocuments>document1.xlx</CompletedPolicyUoloadDocuments>
                    <CompletedPolicyUoloadDocuments>document1.xlx</CompletedPolicyUoloadDocuments>
                    
                <CompletedPolicyUpLoadFilesText><Flake>*</Flake> ანგარიშ–ფაქტურა</CompletedPolicyUpLoadFilesText>
                    <CompletedPolicyUoloadDocuments>document1.xlx</CompletedPolicyUoloadDocuments>
                    <CompletedPolicyUoloadDocuments>document1.xlx</CompletedPolicyUoloadDocuments>

                <CompletedPolicyUpLoadFilesText>დიაგნოზის დასადასტურებლად ჩატარებული გამოკვლევების დასკვნები (ლაბორატორიული ან/და ინსტრუმენტული)</CompletedPolicyUpLoadFilesText>
                    <CompletedPolicyUoloadDocuments>document1.xlx</CompletedPolicyUoloadDocuments>
                    <CompletedPolicyUoloadDocuments>document1.xlx</CompletedPolicyUoloadDocuments>

                    <ForBottomPadding />
                    
            </CompletedPolicyCotent>
        </CompletedPolicyContainer>
    )
}

export default CompletedPolicy
