import React from 'react'

// for styled
import styled from 'styled-components';
import Fonts from 'styles/Fonts';
import Colors from 'styles/Colors';
import Responsive from 'config/Responsive';

// icons
import PlusIconForNewCard from "icons/PlusIcon";
import MasterCard from "icons/MasterCardIcon";
import VisaCard from "icons/VisaCardIcon";
import Star from "icons/StarIcon";
// import DefaultStar from "icons/default-star.svg";
import DefaultStar from "icons/DefaultStarIcon";
import Garbage from "icons/GarbageIcon";



const MyCardContainer = styled.div`

`;
// header style
const MyCardHeader = styled.div`
    height: 140px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
    transition: all 300ms;
        ${Responsive.laptop}{
            padding-left: 30px;
            padding-right: 30px;
            height: 100px;
        }
        ${Responsive.tablet}{
            padding-left: 20px;
            padding-right: 20px;
            height: 100px;
        }
        ${Responsive.mobile}{
            padding-left: unset;
            padding-right: unset;
            height: 150px;
            flex-direction: column;
            justify-content: center;
        }
`;
const MyCardHeaderTitle = styled.h1`
    font-size: 18px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: #636381;
    margin: unset;
    user-select: none;
        ${Responsive.laptop}{
            font-size: 16px;
        }
        ${Responsive.tablet}{
            font-size: 16px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
            margin: 0 auto 0 0;
            padding-left: 10%;
        }
`;
const AddNewCardButton = styled.button`
    width: 22%;
    height: 50px;
    outline: none;
    border: 1px solid ${Colors.Primary};
    border-radius: 6px;
    background-color: ${Colors.Primary};
    color: #fff;
    font-size: 14px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    line-height: 50px;
    overflow: hidden;
        &:focus,
        &:hover{
            outline: none;
            background-color: ${Colors.HoverRed};
        }
    ${Responsive.laptop}{
        width: 25%;
        height: 42px;
        line-height: 42px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
    }
    ${Responsive.tablet}{
        width: 28%;
        height: 42px;
        line-height: 42px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
    }
    ${Responsive.mobile}{
        width: 80%;
        height: 38px;
        line-height: 38px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        margin-top: 50px;
    }
`;
const PlusIconStyled = styled(PlusIconForNewCard)`
    height: 10px;
    width: 10px;
    color: #fff;
    margin-right: 10px;
    margin-top: 18px;
        ${Responsive.laptop}{
            margin-top: 14px;
        }
        ${Responsive.tablet}{
            margin-top: 14px;
        }
        ${Responsive.mobile}{
            margin-top: 12px;
        }
`;
// my cards style 
const MyCardsContainer = styled.div`
    height: 120px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    /* background-color: green; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
    margin-top: 10px;
        ${Responsive.laptop}{
            height: 85px;
            padding-left: 30px;
            padding-right: 30px;
            margin-top: 7px;
        }
        ${Responsive.tablet}{
            height: 85px;
            padding-left: 20px;
            padding-right: 10px;
            margin-top: 7px;
        }
        ${Responsive.mobile}{
            height: 85px;
            padding-left: 20px;
            padding-right: 10px;
            margin-top: 7px;
            justify-content: unset;
            position: relative;
        }
`;
const MyCardTitle = styled.h1`
    margin: unset;
    font-size: 14px;
    font-weight: 400;
    font-family: ${Fonts.FiraGORegular};
    color: ${Colors.Gray};
    user-select: none;
    max-width: 200px;
    width: 100%;
        ${Responsive.laptop}{
            font-size: 14px;
            max-width: 150px;
        }
        ${Responsive.tablet}{
            max-width: 140px;
            font-size: 12px;
        }
        ${Responsive.mobile}{
            max-width: 160px;
            font-size: 12px;
        }
`;
const MyCardTitleSpan = styled.span`
    margin-top: 12px;
    display: inline-block;
        ${Responsive.tablet}{
            margin-top: 15px;
        }
        ${Responsive.mobile}{
            margin-top: 5px;
        }
`;
const MasterCardIcon = styled(MasterCard)`
    margin-right: 20px;
    width: 60px;
        ${Responsive.laptop}{
            width: 40px;
            margin-right: 15px;
        }
        ${Responsive.tablet}{
            width: 40px;
            margin-right: 10px;
        }
        ${Responsive.mobile}{
            width: 40px;
            margin-right: 15px;
        }
`;
const VisaCardIcon = styled(VisaCard)`
    margin-right: 20px;
    width: 60px;
         ${Responsive.laptop}{
            width: 40px;
            margin-right: 15px;
        }
        ${Responsive.tablet}{
            width: 40px;
            margin-right: 10px;
        }
        ${Responsive.mobile}{
            width: 40px;
            margin-right: 15px;
        }
        
`;
const MyCardNumber = styled.h1`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    margin: unset;
    color: ${Colors.Gray};
    font-weight: 400;
        ${Responsive.tablet}{
            font-size: 12px;
        }
        ${Responsive.mobile}{
            font-size: 12px;
            position: absolute;
            left: 78px;
            top: 54%;
        }
`;
const MyCardNumberSpan = styled.span`

`;
const MyCardDate = styled.h1`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    margin: unset;
    color: ${Colors.Gray};
    font-weight: 400;
        ${Responsive.tablet}{
            font-size: 12px;
        }
        ${Responsive.mobile}{
            display: none;
        }
`;
const MyCardIndexButton = styled.button`
    height: fit-content;
    background-color: inherit;
    border: unset;
        &:focus,
        &:hover{
            border: unset;
            outline: none;
        }
    ${Responsive.laptop}{
        max-width: 160px;
        width: 100%;
    }
    ${Responsive.tablet}{
        max-width: 143px;
        width: 100%;
    }
`;
const YallowStarIconJs = styled(Star)`
    height: 18px;
    padding-right: 10px;
    margin-top: 16px;
        ${Responsive.laptop}{
            height: 14px;
            margin-top: 18px;
        }
        ${Responsive.tablet}{
            height: 14px;
            margin-top: 18px;
            padding-right: 5px;
        }
        ${Responsive.mobile}{
            position: absolute;
            top: 42%;
            right: 60px;
            margin-top: unset;
        }
`;
const DefaultStarIcon = styled(DefaultStar)`
    height: 18px;
    padding-right: 10px;
    margin-top: 16px;
        ${Responsive.laptop}{
            height: 14px;
            margin-top: 18px;
        }
        ${Responsive.tablet}{
            height: 14px;
            margin-top: 18px;
            padding-right: 5px;
        }
        ${Responsive.mobile}{
            position: absolute;
            top: 42%;
            right: 60px;
            margin-top: unset;
        }
`;
const MakeBasicCardText = styled.h1`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    margin: unset;
    color: ${Colors.Gray};
    font-weight: 400;
    line-height: 50px;
        ${Responsive.tablet}{
            font-size: 12px;
        }
        ${Responsive.mobile}{
            display: none;
        }
`;
const GarbageIcon = styled(Garbage)`
    height: 18px;
    color: ${Colors.Gray};
        ${Responsive.laptop}{
            height: 16px;
        }
        ${Responsive.tablet}{
            height: 16px;
        }
        ${Responsive.mobile}{
            position: absolute;
            top: 42%;
            right: 20px;
        }
`;
const GarbageButton = styled.button`
    background-color: inherit;
    border: unset;
        &:focus,
        &:hover ${GarbageIcon}{
            border: unset;
            outline: none;
            color: ${Colors.HoverRed};
        }
`; 
const MobileNone = styled.span`
    ${Responsive.mobile}{
        display: none;
    }
`;


export default function MyCards() {
    return (
        <MyCardContainer>

            <MyCardHeader>
                <MyCardHeaderTitle>ბარათები</MyCardHeaderTitle>
                <AddNewCardButton> <PlusIconStyled /> ბარათის დამატება</AddNewCardButton>
            </MyCardHeader>

            <MyCardsContainer>
                <MyCardTitle> <VisaCardIcon /> <MyCardTitleSpan>Visa Card</MyCardTitleSpan> </MyCardTitle>
                <MyCardNumber><MobileNone>ნომერი:</MobileNone> <MyCardNumberSpan>020006**1234</MyCardNumberSpan></MyCardNumber>
                <MyCardDate>ვადა: 12/2023</MyCardDate>
                <MyCardIndexButton> <YallowStarIconJs /> <MakeBasicCardText>ძირითადი ბარათი</MakeBasicCardText>
                </MyCardIndexButton>
                <GarbageButton> <GarbageIcon /> </GarbageButton>
            </MyCardsContainer>
            <MyCardsContainer>
                <MyCardTitle> <MasterCardIcon /> <MyCardTitleSpan>MasterCard</MyCardTitleSpan> </MyCardTitle>
                <MyCardNumber> <MobileNone>ნომერი:</MobileNone> <MyCardNumberSpan>020006**1234</MyCardNumberSpan></MyCardNumber>
                <MyCardDate>ვადა: 12/2023</MyCardDate>
                <MyCardIndexButton> <DefaultStarIcon /> <MakeBasicCardText>გახადე ძირითადი</MakeBasicCardText>
                </MyCardIndexButton>
                <GarbageButton> <GarbageIcon /> </GarbageButton>
            </MyCardsContainer>

        </MyCardContainer>
    )
}
