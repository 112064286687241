import React, { CSSProperties } from "react";
import styled from "styled-components";
import Responsive from "config/Responsive";
import dayjs from 'dayjs';

const DateInputStyled = styled.input`
    height: 42px;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 20px;
    border: 1px solid #E5E6ED;
    color: #aaaabe;
    ${Responsive.mobile} {
       width: 90%;
    }
`;

type Props = {
  value: Date | undefined;
  onChange: (_: Date | undefined) => void;
  style?: CSSProperties;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
};
const DateInput: React.FC<Props> = ({ value, onChange, style,   minDate, maxDate }: Props) => {
  return (
    <>
    <DateInputStyled
    type="date"
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#value
    value={value ? dayjs(value).format('YYYY-MM-DD') : undefined}
    onChange={event => onChange(event?.target.value ? dayjs(event?.target.value).toDate() : undefined)}
    style={style}
    min={minDate ? dayjs(minDate).subtract(1, 'day').format('YYYY-MM-DD') : undefined}
    max={maxDate ? dayjs(maxDate).format('YYYY-MM-DD') : undefined}
      />
    </>
  );
};
export default DateInput;
