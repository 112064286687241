import * as React from "react";

function TravelInsuranceIcon(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 61.801 52"
      {...props}
    >
      <g data-name="Group 3707">
        <g data-name="Group 2909">
          <path
            data-name="Intersection 85"
            d="M10 52A10 10 0 010 42V10A10 10 0 0110 0h50l-7.845 24.71L46.9 41.26a16.838 16.838 0 01-2.184 4.788 13.246 13.246 0 01-8.793 5.828 11.505 11.505 0 01-1.768.124z"
            fill="#ebe8fc"
          />
        </g>
        <g data-name="Group 2910">
          <g data-name="Group 2755">
            <g data-name="palm">
              <g data-name="Group 1339">
                <g data-name="Group 1338">
                  <path
                    data-name="Path 8587"
                    d="M52.456 39.169l-2.474-15.808-2.28-.7-2.5.7-2.253 15.808a.8.8 0 00.2.7.947.947 0 00.7.305h7.71a.947.947 0 00.7-.305.8.8 0 00.197-.7z"
                    fill="#261e56"
                  />
                </g>
              </g>
              <g data-name="Group 1341">
                <g data-name="Group 1340">
                  <path
                    data-name="Path 8588"
                    d="M58.613 19.602a10.955 10.955 0 00-3.9-.792l5.565-1.852a.831.831 0 00.555-1.063 5.381 5.381 0 00-2.912-3.082c-1.36-.609-5.351-1.774-9.336 2.179-.749.648-.691.647-1.284 1.181a10.994 10.994 0 00-1.593-1.733c-3.194-3.201-7.667-2.236-9.03-1.627a5.382 5.382 0 00-2.91 3.082.831.831 0 00.558 1.063l5.566 1.852a10.956 10.956 0 00-3.9.792c-3.149 1.418-3.914 5.358-2.531 7.973a.9.9 0 00.525.429.986.986 0 00.7-.04l12.614-4.3 12.614 4.3a.986.986 0 00.7.04.9.9 0 00.525-.429c1.385-2.615.62-6.555-2.526-7.973z"
                    fill="#261e56"
                  />
                </g>
              </g>
            </g>
          </g>
          <path
            data-name="Path 9549"
            d="M58.642 22.438a2.924 2.924 0 00-1.985-1.057"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.2}
          />
        </g>
        <path
          data-name="Intersection 93"
          d="M45.046 24.434l-10.36 3.532a.987.987 0 01-.7.04.9.9 0 01-.526-.428c-1.383-2.615-.618-6.556 2.527-7.974a10.944 10.944 0 013.9-.792l-5.565-1.852a.83.83 0 01-.558-1.063 5.381 5.381 0 012.912-3.082c1.361-.609 5.834-1.574 9.027 1.627a10.986 10.986 0 011.593 1.732c.593-.534.535-.532 1.283-1.181a9.915 9.915 0 013.5-2.321l-3.143 9.935a19.446 19.446 0 01-2.513 5.525 17.38 17.38 0 01-2.292 2.723z"
          fill="#ff677f"
        />
      </g>
    </svg>
  );
}

export default TravelInsuranceIcon;

