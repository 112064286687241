import * as React from "react";

function AutoInsuranceIcon(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 60 52"
      {...props}
    >
      <g data-name="Group 3707">
        <g data-name="Group 2860">
          <path
            data-name="Intersection 85"
            d="M10 52A10 10 0 010 42V10A10 10 0 0110 0h50l-7.845 24.71L46.9 41.26a16.838 16.838 0 01-2.184 4.788 13.246 13.246 0 01-8.793 5.828 11.505 11.505 0 01-1.768.124z"
            fill="#ebe8fc"
          />
        </g>
        <g data-name="Group 2819">
          <g data-name="Group 2783">
            <path
              d="M57.663 23.347l-2.174-8.2a3.869 3.869 0 00-3.712-2.85H37.223a3.869 3.869 0 00-3.712 2.852l-2.174 8.2A4.008 4.008 0 0029 27.015v6.723a1.97 1.97 0 001.938 2v2.559a1.97 1.97 0 001.938 2h3.177a1.97 1.97 0 001.937-2v-2.559h13.02v2.559a1.97 1.97 0 001.938 2h3.178a1.97 1.97 0 001.938-2v-2.559a1.97 1.97 0 001.938-2v-6.723a4.008 4.008 0 00-2.337-3.668zm-19.872-7.01h13.373l1.744 6.718h-16.86l1.743-6.718z"
              fill="#261e56"
            />
            <path
              data-name="Path 9533"
              d="M56.458 31.413v-2.6"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.6}
            />
          </g>
          <path
            data-name="Intersection 90"
            d="M29.016 34a2.073 2.073 0 01-.017-.263v-6.723a4.007 4.007 0 012.337-3.667l2.174-8.2a3.87 3.87 0 013.712-2.851h13.659l-1.278 4.04H37.791l-1.744 6.719h11.411a17.833 17.833 0 01-2.034 4.077 15.231 15.231 0 01-10.112 6.724 12.809 12.809 0 01-1.857.144z"
            fill="#ff677f"
          />
        </g>
      </g>
    </svg>
  );
}

export default AutoInsuranceIcon;
