import * as React from "react";

function CreditCardIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 22"
      {...props}
    >
      <g data-name="credit card" transform="translate(-100 -625)">
        <rect
          data-name="Rectangle 1603"
          width={30}
          height={22}
          rx={2}
          transform="translate(100 625)"
          fill="#261e56"
        />
        <path
          data-name="Path 9703"
          d="M105.526 640.625h5.518"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={2.5}
        />
      </g>
    </svg>
  );
}

export default CreditCardIcon;
