import Responsive from 'config/Responsive';
import React from 'react';
import styled from 'styled-components';
import Colors from '../styles/Colors';
import Fonts from '../styles/Fonts';

export interface IconCardProps {
    icon?: any;
    iconColor?: string;
    title: string;
  }
const IconCardWrapper = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
        &:hover{
            transition: 100ms all;
            cursor: pointer;
        }
        ${Responsive.desktop}{
            height: 100px;
        }
        ${Responsive.laptop}{
            height: 60px;
        }
        ${Responsive.tablet}{
            height: 60px;
          
        }
        ${Responsive.mobile}{
            height: 60px;
            width: 100%;
        }
`;
const IconCardIconWrapper = styled.div`
    margin-right: 30px;
    margin-left: 10%;
        ${Responsive.desktop}{
            margin-right: 30px;
           ;
        }
        ${Responsive.laptop}{
            margin-right: 18px;
           
        }
        ${Responsive.tablet}{
            margin-right: 18px;
          
        }
        ${Responsive.mobile}{
            margin-right: 20px;
           
        }
`;
const IconCardTitle = styled.div`
    font-size: 16px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: ${Colors.TitleBlue};
        ${Responsive.desktop}{
            font-size: 16px;
        }
        ${Responsive.laptop}{
            font-size: 14px;
        }
        ${Responsive.tablet}{
            font-size: 12px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
        }
`;
const IconCard: React.FC<IconCardProps> = ({ icon: Icon, title, iconColor = Colors.Primary }) => (
    <IconCardWrapper>
        {Icon && <IconCardIconWrapper>
            {Icon}
        </IconCardIconWrapper>}
        <IconCardTitle>{title}</IconCardTitle>
    </IconCardWrapper>
);
export default IconCard;