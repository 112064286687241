import * as React from "react";

function ClockIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      {...props}
    >
      <g data-name="Group 4895" transform="translate(-807 -464)">
        <circle
          data-name="Ellipse 671"
          cx={8}
          cy={8}
          r={8}
          transform="translate(807 464)"
          fill="currentColor"
        />
        <path
          data-name="Path 12923"
          d="M815 468.452v4.133l-2.718 1.962"
          fill="none"
          stroke="#fefefe"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.4}
        />
      </g>
    </svg>
  );
}

export default ClockIcon;