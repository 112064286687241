import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Doctors from "../../components/Doctors";
import PoliciesSlider from "../../components/PoliciesSlider";
import "../../components/ReactSlider/Slider.css";
import { isOfPolicyType, PolicyTypeKeyword } from "../../domain/policyType";
import { RootState } from "../../slices";
import { InsuranceState } from "../../domain";
import { useAppDispatch } from "../../config/store";
import Loader from "../../components/Loader";
import ProviderClinics from "../../components/ProviderClinics";
import PersonalManager from "../../components/PersonalManager";
import styled from "styled-components";
import Responsive from "config/Responsive";
import { hasPersonalManager } from "domain/types";



const DoctorsOrProviderContainer = styled.div`
    
      ${Responsive.laptop}{
        
      }
      ${Responsive.tablet}{
       
      }
      ${Responsive.mobile}{
        
      }
`;


const InsuranceCategory: React.FC = () => {
  let {
    policyTypeKeyword,
  }: { policyTypeKeyword: PolicyTypeKeyword } = useParams();

  const { policies, policiesLoading, doctors, doctorsLoading } = useSelector<
    RootState,
    InsuranceState
  >((data) => data.insurance);
  const policiesOfType = policies.filter(isOfPolicyType(policyTypeKeyword));
  
  const policiesOfTypeWithManagers = policiesOfType.filter(hasPersonalManager);
  
  const userHasPoliciesWithManagers = policiesOfTypeWithManagers.length > 0;

  const dispatch = useAppDispatch();

  useEffect(() => {
    //dispatch(getDoctors(null));
    return () => {};
  });

  return (
    <div>
      {/* <h1 style={{ marginBottom: 30 }}>{policyTypeKeyword}</h1> */}
      {policiesLoading ? (
        <Loader />
      ) : (
        <PoliciesSlider policies={policiesOfType} />
      )}
      {policyTypeKeyword === "health" &&
        (doctorsLoading ? (
          <Loader />
        ) : (
          <DoctorsOrProviderContainer>
            {/* <Doctors doctors={doctors} /> */}
            <ProviderClinics />
          </DoctorsOrProviderContainer>
        ))}
      {userHasPoliciesWithManagers && policyTypeKeyword === "auto" && (
        <div style={{ width: 280, paddingLeft: 15 }}>
          <PersonalManager policy={policiesOfTypeWithManagers[0]} />
        </div>
      )}
    </div>
  );
};

export default InsuranceCategory;
