import * as React from "react";

function MoneyBag(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      data-name="money bag"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 20"
      {...props}
    >
      <g data-name="Group 2582">
        <path
          data-name="Path 9454"
          d="M12.214 4.352l.61-3.411a.735.735 0 00-.406-.9.585.585 0 00-.2-.037H5.786a.681.681 0 00-.643.714.787.787 0 00.033.227l.61 3.411C2.248 4.844.006 8.182 0 12.143v4.286A3.411 3.411 0 003.214 20h11.572A3.411 3.411 0 0018 16.429v-4.286c-.006-3.961-2.248-7.299-5.786-7.791z"
          fill="currentColor"
        />
      </g>
      <path
        data-name="Path 9465"
        d="M4.026 10.8a2.885 2.885 0 011.82-2.748"
        fill="currentColor"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.4}
      />
    </svg>
  );
}

export default MoneyBag;
