import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";

// Styles
import GlobalStyles from "./theme/GlobalStyles";
import { ThemeProvider } from "emotion-theming";
import { theme } from "./theme/theme";

// Redux
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./config/store";

// let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <App />
      </ThemeProvider>
      {/* </PersistGate> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
