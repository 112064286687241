import * as React from "react";

function PolicyCardArrow(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23 16.24"
      {...props}
    >
      <g data-name="Group 3481">
        <g data-name="Group 3027">
          <path
            data-name="Path 8799"
            d="M21.498 8.107h-20"
            fill="none"
            stroke="#261e56"
            strokeLinecap="round"
            strokeWidth={3}
          />
          <g data-name="Group 2472">
            <path
              data-name="Path 9328"
              d="M15.886 14.12l5.614-6-5.614-6"
              fill="none"
              stroke="#261e56"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={3}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PolicyCardArrow;
