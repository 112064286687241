import * as React from "react";
import Colors from '../styles/Colors';

function HealthIcon(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 60 52"
      {...props}
    >
      <g data-name="Group 3707">
        <g data-name="Group 2815">
          <path
            data-name="Intersection 85"
            d="M10 52A10 10 0 010 42V10A10 10 0 0110 0h50l-7.845 24.71L46.9 41.26a16.838 16.838 0 01-2.184 4.788 13.246 13.246 0 01-8.793 5.828 11.505 11.505 0 01-1.768.124z"
              fill={Colors.PrimaryVeryLight}
          />
        </g>
        <g data-name="Group 2818">
          <g data-name="cross">
            <path
              data-name="Path 8790"
              d="M56.1 20.969h-5.911l.01-5.756a2.936 2.936 0 00-2.926-2.917h-4.4a2.937 2.937 0 00-2.94 2.93l-.01 5.756-5.958.014a2.937 2.937 0 00-2.926 2.92v4.4a2.937 2.937 0 002.936 2.93l5.957-.013.01 6.152a2.936 2.936 0 002.927 2.911h4.4a2.937 2.937 0 002.936-2.93l-.01-6.152h5.913a2.936 2.936 0 002.931-2.918v-4.4a2.936 2.936 0 00-2.939-2.927z"
              fill="#261e56"
            />
          </g>
          <path
            data-name="Path 9560"
            d="M55.955 24.126h-1.66"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.6}
          />
          <path
            data-name="Intersection 89"
            d="M31.579 29.997a2.915 2.915 0 01-.54-1.685v-4.4a2.937 2.937 0 012.928-2.915l5.957-.014.01-5.756a2.937 2.937 0 012.936-2.93h4.4a2.933 2.933 0 011.252.285l-1.583 5.026a19.478 19.478 0 01-2.512 5.525 15.232 15.232 0 01-10.115 6.724 13.071 13.071 0 01-1.857.144z"
              fill={Colors.PrimaryLight}
          />
        </g>
      </g>
    </svg>
  );
}

export default HealthIcon;

