import * as React from "react";

function FreightIcon(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 60 52"
      {...props}
    >
      <g data-name="Group 4869">
        <g data-name="Group 4868">
          <path
            data-name="Intersection 101"
            d="M10 52A10 10 0 010 42V10A10 10 0 0110 0h50l-7.845 24.71L46.9 41.26a16.838 16.838 0 01-2.184 4.788 13.246 13.246 0 01-8.793 5.828 11.505 11.505 0 01-1.768.124z"
            fill="#ebe8fc"
          />
          <g data-name="Group 4608">
            <g data-name="Group 4606">
              <g data-name="Group 4605">
                <path
                  data-name="Exclusion 12"
                  d="M52.553 39.503H35.444A5.334 5.334 0 0130 34.295V17.703a5.334 5.334 0 015.444-5.2h5.3v9.3a.527.527 0 00.566.474l3.361-1.984 3.429 1.986a.527.527 0 00.566-.474v-9.302h3.888a5.334 5.334 0 015.446 5.2v16.592a5.334 5.334 0 01-5.447 5.208z"
                  fill="#261e56"
                />
                <path
                  data-name="Intersection 94"
                  d="M30 17.708a5.336 5.336 0 015.444-5.205h5.3v9.3a.528.528 0 00.567.473l3.361-1.986 2.288 1.33A26.354 26.354 0 0130 28.31zm18.665-5.205h3.889a5.728 5.728 0 01.846.063 24.883 24.883 0 01-4.733 7.373z"
                  fill="#ff677f"
                />
              </g>
            </g>
            <path
              data-name="Path 12878"
              d="M54.112 34.209v-2.6"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.6}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FreightIcon;
