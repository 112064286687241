import React, { useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../slices";
import { Policy } from "../../domain";
import { typeOfPolicy } from "../../domain/policyType";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import Colors from "styles/Colors";
import Fonts from "styles/Fonts";
import Responsive from "config/Responsive";

import PolicyLimitsAccordion from "../../screens/Policies/PolicyLimitsAccordion";
import { useAppDispatch } from "config/store";
import { getPolicyLimits } from "slices/insuranceSlice";
import { PolicyLimit, InsuranceState } from '../../domain/types';
import Loader from "components/Loader";

const HealthInsuranceLimitsTabs = styled(Tabs)`
  background-color: #ffffff !important;
  height: 120px !important;
  opacity: unset !important;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  ${Responsive.laptop} {
    height: 85px !important;
    min-height: 85px !important;
  }
  ${Responsive.tablet} {
    height: 85px !important;
    min-height: 85px !important;
  }
  ${Responsive.mobile} {
    height: 75px !important;
    min-height: 75px !important;
    position: relative;
  }
`;
const HealthInsuranceLimitsTab = styled(Tab)`
  font-size: 18px !important;
  outline: none !important;
  color: ${Colors.Primary} !important;
  max-width: 30% !important;
  width: 100% !important;
  font-family: ${Fonts.FiraGOMtavruliRegular} !important;
  font-weight: 400 !important;
  margin-top: 38px !important;
  opacity: unset !important;
  ${Responsive.laptop} {
    font-size: 14px !important;
    max-width: 30% !important;
    margin-top: unset !important;
    height: 85px !important;
  }
  ${Responsive.tablet} {
    font-size: 14px !important;
    max-width: 30% !important;
    min-width: 100px !important;
    margin-top: unset !important;
    height: 85px !important;
  }
  ${Responsive.mobile} {
    font-size: 14px !important;
    max-width: 50% !important;
    margin-top: unset !important;
    height: 75px !important;
    padding: unset !important;
    line-height: 15px !important;
    &:focus {
      width: 100% !important;
    }
  }
`;
const HealthInsuranceLimitsContainer = styled.div`
  ${Responsive.laptop} {
  }
  ${Responsive.tablet} {
  }
  ${Responsive.mobile} {
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const PolicyLimits: React.FC = () => {
  let { policyPolid } = useParams() as any;

  const policy = useSelector<RootState, Policy>(
    (state) =>
      state.insurance.policies.find((p) => p.policyPolid === policyPolid)!
  );
  const { policyLimits, policyLimitsLoading } = useSelector<RootState, InsuranceState>(state => state.insurance);

  const policyType = policy && typeOfPolicy(policy);
  const PolicyTypeIcon = policyType?.icon;
  // 
  const history = useHistory();
  const dispatch = useAppDispatch();
  //
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (policy) {
      dispatch(getPolicyLimits({ policyPolid: policy.policyPolid, policyCustomerId: policy.policyCustomerId }));
    }
  }, [policy, policyPolid])


  return (
    <HealthInsuranceLimitsContainer className={classes.root}>
      <AppBar position="static">
        <HealthInsuranceLimitsTabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <HealthInsuranceLimitsTab
            label="რას ფარავს დაზღვევა"
            {...a11yProps(0)}
          />
          {/* <HealthInsuranceLimitsTab
            label="რას არ ფარავს დაზღვევა"
            {...a11yProps(1)}
          /> */}
        </HealthInsuranceLimitsTabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        {policyLimitsLoading ? <Loader /> : (
          <PolicyLimitsAccordion limits={policyLimits} />
        )}
      </TabPanel>

      {/* <TabPanel value={value} index={1}>
        <PolicyLimitsAccordion />
      </TabPanel> */}
    </HealthInsuranceLimitsContainer>
  );
};
export default PolicyLimits;
