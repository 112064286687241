import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { isOfPolicyType, typeOfPolicy } from "../../domain/policyType";
import Responsive from "../../config/Responsive";
// js icons
import CopyIcon from "../../icons/CopyIcon";
import { RootState } from "../../slices";
import { InsuranceState, Policy } from "../../domain";
import Colors from "../../styles/Colors";
import Fonts from "../../styles/Fonts";
import RightArrow from "icons/RightArrow";

import dayjs from "dayjs";
import PersonalManager from "components/PersonalManager";
import { hasPersonalManager } from "domain/types";
dayjs.locale("ka");

const Wrapper = styled.div`
`;

// responsive +

// line
const ContentLine = styled.div`
  width: 1px;
  height: 60px;
  background-color: #f2f5f7;
  ${Responsive.mobile} {
    display: none;
  }
`;
const MobileContentLine = styled.div`
  width: 90%;
  height: 1px;
  background-color: #f2f5f7;
  margin: 17px auto;
  display: none;
  ${Responsive.mobile} {
    display: block;
  }
`;

const PolicyDetailContainer = styled.div`
  height: 240px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
  ${Responsive.laptop} {
    height: 170px;
  }
  ${Responsive.tablet} {
    height: 170px;
  }
  ${Responsive.mobile} {
    height: fit-content;
    padding-bottom: 70px;
    position: relative;
  }
`;
const PolicyDetalSection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${Responsive.mobile} {
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
  }
`;
const InsurancePersonImageBox = styled.div`
  height: 80px;
  width: 80px;
  display: inline-block;
  ${Responsive.laptop} {
    height: 56px;
    width: 56px;
  }
  ${Responsive.tablet} {
    height: 50px;
    width: 50px;
  }
  ${Responsive.mobile} {
    height: 70px;
    width: 70px;
  }
`;
const InsurancePersonNameLast = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  font-weight: 400;
  color: #636381;
  margin: unset;
  ${Responsive.laptop} {
    font-size: 14px;
  }
  ${Responsive.tablet} {
    font-size: 14px;
  }
  ${Responsive.mobile} {
    font-size: 14px;
  }
`;
const UpLoadFileText = styled.h1`
  font-size: 12px;
  font-weight: 400;
  color: #aaaabe;
  margin: unset;
  font-family: ${Fonts.FiraGORegular};
  margin-right: 5px;
`;
const UpLoadFileDiv = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #636381;
  margin: unset;
  font-family: ${Fonts.FiraGORegular};
  display: inline-block;
`;
const UploadFileContainer = styled.div`
  margin-top: 5px;
  display: flex;
`;
const ImageRightSideBox = styled.div`
  display: inline-block;
  margin-left: 30px;
  ${Responsive.laptop} {
    margin-left: 20px;
  }
  ${Responsive.tablet} {
    margin-left: 20px;
  }
`;
const FirstimageSectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${Responsive.mobile} {
    margin-top: 25px;
    margin-left: 8%;
  }
`;
const PolicyInfoDiv = styled.div`
  ${Responsive.mobile} {
    margin-left: 8%;
  }
`;
const PolicyNumber = styled.h1`
  font-size: 14px;
  color: #aaaabe;
  font-weight: 400;
  margin: unset;
  ${Responsive.laptop} {
    font-size: 12px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
  }
`;
const PolicyNumberId = styled.span`
  font-size: 14px;
  color: #636381;
  font-weight: 600;
  margin: unset;
  ${Responsive.laptop} {
    font-size: 12px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
  }
`;
const PolicyStatusDiv = styled.div`
  ${Responsive.mobile} {
    margin-left: 8%;
  }
`;
const PolicyStatus = styled.h1`
  font-size: 14px;
  color: #aaaabe;
  font-weight: 400;
  margin: unset;
  font-family: ${Fonts.FiraGORegular};
  ${Responsive.laptop} {
    font-size: 12px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
  }
`;
const PolicyStatusAmswer = styled.span`
  font-size: 14px;
  color: #44c2d3;
  font-weight: 400;
  margin: unset;
  ${Responsive.laptop} {
    font-size: 12px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
  }
`;
const PolicyStatusLimit = styled.span`
  font-size: 14px;
  color: #394c5a;
  font-weight: 400;
  margin: unset;
  ${Responsive.laptop} {
    font-size: 12px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
  }
`;
const SendButtonDiv = styled.div`
  margin-top: 70px;
  ${Responsive.laptop} {
    margin-top: 42px;
  }
  ${Responsive.tablet} {
    margin-top: 42px;
  }
  ${Responsive.mobile} {
    width: 100%;
    margin-top: 80px;
  }
`;
const SendButton = styled.button`
  width: 180px;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid #261e56;
  border-radius: 6px;
  font-size: 16px;
  font-family: ${Fonts.FiraGOMtavruliRegular};
  color: #261e56;
  outline: none;
  &:hover,
  &:focus {
    background-color: ${Colors.HoverRed};
    color: #fff;
    border: 1px solid ${Colors.HoverRed};
    outline: none;
  }
  ${Responsive.laptop} {
    width: 150px;
    height: 40px;
    font-size: 14px;
  }
  ${Responsive.tablet} {
    width: 120px;
    height: 35px;
    font-size: 12px;
    margin: 0 auto;
    display: block;
  }
  ${Responsive.mobile} {
    width: 80%;
    margin: 0 auto;
    display: block;
  }
`;
const PolicyConditions = styled(Link)`
  display: block;
  font-size: 14px;
  font-family: ${Fonts.FiraGORegular};
  color: #261e56;
  outline: none;
  text-decoration: none;
  margin-top: 63px;
  margin-bottom: -15px;
  &:hover {
    text-decoration: none;
    color: ${Colors.HoverRed};
  }
  ${Responsive.laptop} {
    font-size: 12px;
    margin-top: 41px;
    margin-bottom: -17px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    margin-top: 48px;
    margin-bottom: -17px;
  }
  ${Responsive.mobile} {
    margin-top: 25px;
    text-align: center;
  }
`;
const LinkRightArrow = styled(RightArrow)`
  width: 16px;
  margin-top: -2px;
  margin-left: 5px;
  ${Responsive.laptop} {
    margin-top: -3px;
  }
`;
const PolicyDetailTateDiv = styled.div`
  margin-left: 2.5%;
  ${Responsive.mobile} {
    position: absolute;
    top: 55.4%;
    left: 8%;
    margin-left: unset;
  }
`;
const PolicyDetailStartDate = styled.h1`
  font-size: 12px;
  font-weight: 400;
  color: #aaaabe;
  margin: unset;
  font-family: ${Fonts.FiraGORegular};
  margin-right: 15px;
  ${Responsive.mobile} {
    display: block;
    margin-bottom: 7px;
  }
`;
const PolicyDetailStartDateSpan = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #636381;
  margin: unset;
  font-family: ${Fonts.FiraGORegular};
`;
const PolicyDetailFinishtDate = styled.h1`
  font-size: 12px;
  font-weight: 400;
  color: #aaaabe;
  margin: unset;
  font-family: ${Fonts.FiraGORegular};
`;
const PolicyDetailFinishtDateSpan = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #636381;
  margin: unset;
  font-family: ${Fonts.FiraGORegular};
`;

const MyPoliciesDetails: React.FC = () => {
  let { policyPolid } = useParams() as any;

  const policy = useSelector<RootState, Policy>(
    (state) =>
      state.insurance.policies.find((p) => p.policyPolid === policyPolid)!
  );


  const policyType = policy && typeOfPolicy(policy);
  const PolicyTypeIcon = policyType?.icon;

  const history = useHistory();

  const limitsUrl = `/policy-limits/${policyPolid}`;

  return (
    <Wrapper>
    <PolicyDetailContainer>
      <PolicyDetalSection>
        <FirstimageSectionContainer>
          {PolicyTypeIcon && (
            <InsurancePersonImageBox>
              <PolicyTypeIcon style={{ height: 100 + "%", width: 100 + "%" }} />
            </InsurancePersonImageBox>
          )}
          <ImageRightSideBox>
            <InsurancePersonNameLast>
              {policy?.objectName || "უცნობი დაზღვეული ობიექტი"}
            </InsurancePersonNameLast>
            {/* <UploadFileContainer> */}
            {/*                               <UpLoadFileText>პ.დამ. დოკ: </UpLoadFileText>  */}
            {/*                               <UpLoadFileDiv> doc..xlx</UpLoadFileDiv>  */}
            {/*                           </UploadFileContainer> */}
          </ImageRightSideBox>
        </FirstimageSectionContainer>

        <ContentLine />
        <MobileContentLine />

        <PolicyInfoDiv>
          <PolicyNumber>
            პოლისის N: <PolicyNumberId>{policy?.policyNumber}</PolicyNumberId>
          </PolicyNumber>
          <br />
          <PolicyNumber>
            ბარათის N: <PolicyNumberId>N1BC 92 AC 219</PolicyNumberId>
          </PolicyNumber>
        </PolicyInfoDiv>

        <ContentLine />
        <MobileContentLine />

        <PolicyStatusDiv>
          <PolicyStatus>
            სტატუსი: <PolicyStatusAmswer>აქტიური</PolicyStatusAmswer>
          </PolicyStatus>
          <br />
          {/* <PolicyStatus> */}
          {/*         ლიმიტი: <PolicyStatusLimit>2 000 ₾</PolicyStatusLimit> */}
          {/*       </PolicyStatus> */}
        </PolicyStatusDiv>

        {/* <ContentLine /> */}
        <MobileContentLine />

        <SendButtonDiv>
          <SendButton onClick={() => history.push(limitsUrl)}>
            ლიმიტები
          </SendButton>
          <PolicyConditions to={limitsUrl}>
            {" "}
            პოლისის პირობები <LinkRightArrow />{" "}
          </PolicyConditions>
        </SendButtonDiv>
      </PolicyDetalSection>

      <PolicyDetailTateDiv>
        <PolicyDetailStartDate>
          დაიწყო:{" "}
          <PolicyDetailStartDateSpan>
            {dayjs(policy?.startsAt).format("DD MMM YYYY")}
          </PolicyDetailStartDateSpan>
        </PolicyDetailStartDate>
        <PolicyDetailFinishtDate>
          ვადა:{" "}
          <PolicyDetailFinishtDateSpan>
            {dayjs(policy?.endsAt).format("DD MMM YYYY")}
          </PolicyDetailFinishtDateSpan>
        </PolicyDetailFinishtDate>
      </PolicyDetailTateDiv>

      {/* { policy?.policyNumber && (
            <CopyToClipboard text={policy?.policyNumber} onCopy={() => alert("წარმატებით დაკოპირდა")} >
                <CopyIcon style={{ marginTop: 6, marginLeft: 8, cursor: "pointer", color: Colors.Gray}}/>  
            </CopyToClipboard>)} */}
    </PolicyDetailContainer>
    <div style={{marginTop: 30}}>
      {/*isOfPolicyType("auto")(policy) && */ hasPersonalManager(policy) && (
        <div style={{ width: 280, paddingLeft: 15 }}>
          <PersonalManager policy={policy} />
        </div>
      )}
    </div>
    </Wrapper>
  );
};

export default MyPoliciesDetails;
