import * as React from "react";

function CloseIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22.627 22.627"
      {...props}
    >
      <path
        data-name="Add Icon"
        d="M15.517 16.97l-4.203-4.202-4.203 4.203a1.028 1.028 0 11-1.454-1.454l4.203-4.203L5.657 7.11a1.028 1.028 0 111.454-1.454l4.203 4.203 4.203-4.203a1.028 1.028 0 011.454 1.454l-4.203 4.203 4.203 4.203a1.028 1.028 0 11-1.454 1.454z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CloseIcon;
