import React from 'react';
import { Link } from "react-router-dom";

function ReferencesList() {
  return (
    <section>
      <div className="card" style={{ width: 360 }} >
        <img className="card-img-top m-auto pt-2" style={{ width: 200 }} src="https://eshendetesia.com/images/user-profile.png" alt="User avatar" />
        <div className="card-body">
          <h5 className="card-title text-center">ოპერაცია</h5>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">თარიღი: 23 ივლისი 2020</li>
          <li className="list-group-item">თანხარ: 4000 ლარი</li>
          <li className="list-group-item">სტატუსი: დამუშავების პროცესში</li>
        </ul>
        <div className="card-body">
          <Link to="/Referances-Detail-Page">სრულად</Link>
        </div>
      </div>
    </section>
  );
}

export default ReferencesList;
