import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { useSelector } from "react-redux";
import {
  getPolicies,
  getCompensations,
  getCompensationHistory,
  uploadFile,
} from "../../slices/insuranceSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import { RootState } from "../../slices";
import {
  InsuranceState,
  Policy,
  Compensation,
  AddCompensationUploadType,
  AuthState,
} from "../../domain";
import {
  CompensationTypeKeyword,
  CompensationType,
  compensationTypes,
  compensationFileTypeTitles,
} from "../../domain/compensationType";
import { useAppDispatch } from "../../config/store";
import { addCompensation } from "../../slices/insuranceSlice";

import styled from "styled-components";
import Colors from "../../styles/Colors";
import Fonts from "../../styles/Fonts";
import PlusIcon from "../../icons/PlusIcon";
import FileUploadIcon from "../../icons/FileUploadIcon";
import { Button, colors, IconButton } from "@material-ui/core";
import Responsive from "config/Responsive";

import FillRequiredPersonalInformation from '../../components/FillRequiredPersonalInformation';

const NewFormsContainer = styled.div`
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 100px;
  ${Responsive.laptop} {
  }
  ${Responsive.tablet} {
  }
  ${Responsive.mobile} {
  }
`;
const Label = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  margin: 40px 0 30px 0;
  color: ${Colors.TitleBlue};
`;
const Select = styled.select`
  height: 50px;
  border-radius: 4px;
  max-width: 320px;
  min-width: 150px;
  width: 100%;
  outline: none;
  border: 1px solid #e5e6ed;
  line-height: 50px;
  padding-left: 20px;
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  color: #636381;
`;
const NewFormsTitle = styled.h1`
  font-size: 18px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  font-weight: 400;
  color: ${Colors.TitleBlue};
  margin: 50px 0 50px 50px;
`;
const NewFormsLine = styled.span`
  height: 1px;
  width: 100%;
  background-color: ${Colors.Gray};
  display: block;
`;
const NewFormsContentBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 50px;
`;
const UpLoadDocumentsTitle = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  color: ${Colors.TitleBlue};
  margin-top: 40px;
  margin-bottom: unset;
`;
const UpLoadDocumentsWarning = styled.h1`
  font-size: 14px;
  font-family: ${Fonts.FiraGORegular};
  color: ${Colors.Gray};
  margin-top: 20px;
  margin-bottom: unset;
  font-weight: 400;
`;
const WarningText = styled.span`
  font-family: ${Fonts.FiraGOMtavruliBold};
  color: ${Colors.Primary};
`;
const UpLoadDocumentStampTitle = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  color: ${Colors.TitleBlue};
  margin-bottom: unset;
`;
const UploadFileDiv = styled.div`
  margin-top: 40px;
`;
const CustomFileUpload = styled.div`
  height: 50px;
  // max-width: 1000px;
  width: 100%;
  font-size: 14px;
  font-family: ${Fonts.FiraGORegular};
  color: ${Colors.LightGray};
  border: 1px solid ${Colors.LightGray};
  border-radius: 6px;
`;
const CustomFileUploadLabel = styled.label`
  margin: unset;
  // max-width: 1000px;
  width: 100%;
`;
const CustomFileUploadSpan = styled.span`
  font-size: 14px;
  font-family: ${Fonts.FiraGORegular};
  color: ${Colors.LightGray};
  margin: unset;
  line-height: 50px;
  margin-top: -4px;
`;
const CustomUploadIcon = styled(FileUploadIcon)`
  height: 18px;
`;
const UploadFileButton = styled.button`
  height: 50px;
  line-height: 50px;
  width: 200px;
  border: 2px dashed ${Colors.LightGray};
  background-color: inherit;
  border-radius: 6px;
  font-size: 14px;
  color: ${Colors.Gray};
  font-family: ${Fonts.FiraGOMtavruliRegular};
  font-weight: 400;
  margin-left: 20px;
  &:focus {
    outline: none;
  }
`;
const PlusCustomIcon = styled(PlusIcon)`
  height: 10px;
  margin-top: 17px;
  margin-right: 5px;
`;
const CustomFileUpLoadBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`;
const SendButton = styled.button`
  width: 200px;
  height: 50px;
  line-height: 50px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background-color: ${Colors.Primary};
  border: 1px solid ${Colors.Primary};
  border-radius: 6px;
  margin: 130px 0 60px auto;
  &:hover,
  &:focus {
    background-color: ${Colors.HoverRed};
    outline: none;
  }
`;

export default function AddCompensation() {
  const {
    user
  } = useSelector<RootState, AuthState>(data => data.auth);
  const {
    policies,
    policiesLoading,
    compensations,
    compensationsLoading,
    compensationHistory = [],
    compensationHistoryLoading,
    addCompensationLoading,
  } = useSelector<RootState, InsuranceState>((data) => data.insurance);
  //
  const [policyNumber, setSelectedPolicyNumber] = useState("");
  const [policyPolid, setSelectedPolicyPolid] = useState("");
  const [insuredPersonalNumber, setSelectedInsuredPersonalNumber] = useState("");
  const [
    compensationTypeKeyword,
    setCompensationTypeKeyword,
  ] = useState<CompensationTypeKeyword | null>(null);
  const [comment, setComment] = useState("");
  const EMPTY = "EMPTY";
  const [filesByType, setFilesByType] = useState<
    Record<AddCompensationUploadType, string[]>
  >({
    // shared file types between compensation types
    // files specific to ambulatory type
    ambulatoryForm100: [EMPTY],
    ambulatoryCheck: [EMPTY],
    ambulatoryIncomeOrder: [EMPTY],
    ambulatoryCalculation: [EMPTY],
    ambulatoryInvoice: [EMPTY],
    ambulatoryExamResults: [EMPTY],
    // files specific to meds type
    medsForm100: [EMPTY],
    medsCheck: [EMPTY],
    medsIncomeOrder: [EMPTY],
    // files specific to dental type
    dentalForm100: [EMPTY],
    dentalCheck: [EMPTY],
    dentalCalculation: [EMPTY],
    dentalIncomeOrder: [EMPTY],
    dentalExamResults: [EMPTY],
    dentalXrayPhotos: [EMPTY],
  });
  const addNewFileBox = (fileType: AddCompensationUploadType) => {
    if (filesByType[fileType].filter((file) => file === EMPTY).length > 0) {
      // there's already an empty file box
      return;
    }
    setFilesByType({
      ...filesByType,
      [fileType]: [...filesByType[fileType], EMPTY],
    });
  };
  const setUploadedFile = (
    fileType: AddCompensationUploadType,
    index: number,
    path: string
  ) => {
    setFilesByType({
      ...filesByType,
      [fileType]: filesByType[fileType].map((existingFile, indexCurrent) => {
        console.log({
          indexCurrent,
          index,
          path,
          existingFile,
        });
        return indexCurrent === index ? path : existingFile;
      }),
    });
  };
  //
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { register } = useForm();
  //
  useEffect(() => {
    dispatch(getPolicies(null));
    dispatch(getCompensations(null));
    policyPolid && dispatch(getCompensationHistory({ policyPolid }));
  }, []);
  //
  return (
    <NewFormsContainer>
      <NewFormsTitle>ახალი ანაზღაურება</NewFormsTitle>
    <NewFormsLine />
    {!(user?.email && user?.phone && user?.bankAccountNumber) ? (
      <FillRequiredPersonalInformation />
    ) : (
      <NewFormsContentBox>
        <Label>აირჩიე დაზღვეული პირი</Label>
        <Select
          onChange={(event) => {
            const value = event.currentTarget.value;
            if (value) {
              const policy = policies.find(
                (p: Policy) => p.policyPolid === value
              );
              policy && setSelectedPolicyNumber(policy.policyNumber);
              policy && setSelectedPolicyPolid(policy.policyPolid);
              policy && setSelectedInsuredPersonalNumber(policy.personalNumber);
            } else {
              setSelectedPolicyNumber("");
              setSelectedPolicyPolid("");
              setSelectedInsuredPersonalNumber("");
            }
            event.stopPropagation();
          }}
        >
          <option key="empty" value={""}>
            აირჩიე
          </option>
          {policies.map((p) => (
            <option key={p.policyPolid} value={p.policyPolid}>
              {p.objectName || p.cardNumber}
            </option>
          ))}
        </Select>
        <Label>აირჩიე სერვისის ტიპი</Label>
        <Select
          onChange={(event) => {
            const value = event.currentTarget.value;
            setCompensationTypeKeyword(value as CompensationTypeKeyword | null);
            event.stopPropagation();
          }}
        >
          <option key="empty" value={""}>
            აირჩიე
          </option>
          {Object.values(compensationTypes).map(({ name, keyword }) => (
            <option key={keyword} value={keyword}>
              {name}
            </option>
          ))}
        </Select>
        {compensationTypeKeyword && (
          <>
            <UpLoadDocumentsTitle>ატვირთე დოკუმენტაცია</UpLoadDocumentsTitle>
            <UpLoadDocumentsWarning>
              ვარსკვლავით <WarningText>(*)</WarningText> მონიშნული ველები
              სავალდებულოა!
            </UpLoadDocumentsWarning>
          </>
        )}

        {Object.keys(filesByType)
          .filter(
            (fileType: string) =>
              compensationTypeKeyword &&
              fileType.indexOf(compensationTypeKeyword) === 0
          )
          .map((fileType: string) =>
            filesByType[fileType as unknown as AddCompensationUploadType].map(
              (fileName: string, fileIndex: number) => {
                const fileInputId = `icon-button-file-${fileType}-${fileIndex}`;
                return (
                  <UploadFileDiv key={`${fileType}-${fileName}`}>
                    <UpLoadDocumentStampTitle>
                      {[
                        "ambulatoryForm100",
                        "ambulatoryCheck",
                        "ambulatoryIncomeOrder",
                        "medsCheck",
                        "medsIncomeOrder",
                        "dentalCheck",
                        "dentalIncomeOrder",
                      ].indexOf(fileType) !== -1 ? (
                        <WarningText>*</WarningText>
                      ) : null}
                  {compensationFileTypeTitles[fileType as unknown as AddCompensationUploadType]}
                    </UpLoadDocumentStampTitle>
                    <CustomFileUpLoadBox>
                      <CustomFileUpload>
                        <input
                          style={{ display: "none" }}
                          accept="image/*"
                          id={fileInputId}
                          type="file"
                          onChange={(event) => {
                            const files = event.target.files;
                            if (!files || !files.length) {
                              return;
                            }
                            const file = files[0]; // for now only single file upload
                            dispatch(uploadFile({ file }))
                              .then(unwrapResult)
                              .then(({ path }) => {
                                //alert("ფაილი აიტვირთა");
                                setUploadedFile(
                                  fileType as AddCompensationUploadType,
                                  fileIndex,
                                  path
                                );
                              })
                              .catch((error) => {
                                console.log(
                                  "error while uploading file",
                                  error
                                );
                                alert(
                                  (error.messages && error.messages[0]) ||
                                    "ფაილის ატვირთვა ვერ მოხერხდა."
                                );
                              });
                          }}
                        />
                        <CustomFileUploadLabel htmlFor={fileInputId}>
                          <IconButton
                            style={{ width: 100 + "%" }}
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <CustomFileUploadSpan>
                  {fileName !== EMPTY ? /* fileName */ `ფაილი ${fileIndex + 1}` : "ატვირთე ფაილი"}
                            </CustomFileUploadSpan>
                            <CustomUploadIcon />
                          </IconButton>
                        </CustomFileUploadLabel>
                      </CustomFileUpload>
                      <UploadFileButton
                        onClick={() =>
                          addNewFileBox(fileType as AddCompensationUploadType)
                        }
                      >
                        {" "}
                        <PlusCustomIcon /> დამატება
                      </UploadFileButton>
                    </CustomFileUpLoadBox>
                  </UploadFileDiv>
                );
              }
            )
          )}

        {addCompensationLoading ? `იტვირთება..` : (
      <SendButton
          onClick={() => {
            if (!policyNumber || !policyPolid) {
              alert("გთხოვთ, აირჩიოთ დაზღვეული პირი");
              return;
            }
            const policy = policies.find(p => p.policyNumber === policyNumber && p.policyPolid === policyPolid);
            if (!compensationTypeKeyword) {
              alert("გთხოვთ, აირჩიოთ სერვისის ტიპი");
              return;
            }
            switch (compensationTypeKeyword) {
              case "ambulatory": {
                if (
                  !filesByType.ambulatoryForm100.filter((f) => f !== EMPTY)
                    .length
                ) {
                  alert("გთხოვთ, ატვირთოთ ფორმა 100");
                  return;
                }
                if (
                  !filesByType.ambulatoryCheck.filter((f) => f !== EMPTY).length
                ) {
                  alert("გთხოვთ, ატვირთოთ სალაროს ჩეკი");
                  return;
                }
                if (
                  !filesByType.ambulatoryIncomeOrder.filter((f) => f !== EMPTY)
                    .length
                ) {
                  alert("გთხოვთ, ატვირთოთ შემოსავლის ორდერი");
                  return;
                }
                break;
              }
              case "meds": {
                if (!filesByType.medsCheck.filter((f) => f !== EMPTY).length) {
                  alert("გთხოვთ, ატვირთოთ სალაროს ჩეკი");
                  return;
                }
                if (
                  !filesByType.medsIncomeOrder.filter((f) => f !== EMPTY).length
                ) {
                  alert("გთხოვთ, ატვირთოთ შემოსავლის ორდერი");
                  return;
                }
                break;
              }
              case "dental": {
                if (
                  !filesByType.dentalCheck.filter((f) => f !== EMPTY).length
                ) {
                  alert("გთხოვთ, ატვირთოთ სალაროს ჩეკი");
                  return;
                }
                if (
                  !filesByType.dentalIncomeOrder.filter((f) => f !== EMPTY)
                    .length
                ) {
                  alert("გთხოვთ, ატვირთოთ შემოსავლის ორდერი");
                  return;
                }
                break;
              }
            }
            dispatch(
              addCompensation({
                policyNumber,
                policyPolid,
                insuredPersonalNumber,
                type: compensationTypeKeyword,
                title: policy?.objectName || `${policyNumber} ${policyPolid}`,
                status: "request",
                comment: comment,
                files: Object.fromEntries(Object.keys(filesByType)
                  .map((uploadType: string) =>
                    [uploadType as AddCompensationUploadType, JSON.stringify(
                      filesByType[uploadType as AddCompensationUploadType].filter(f => f !== EMPTY)
                    )]
                      )) as unknown as Record<AddCompensationUploadType, string[]>,
              })
            )
              .then(unwrapResult)
              .then((result) => {
                // reload compensation list
                dispatch(getCompensations(null));
                // notify the user
                alert("განაცხადი წარმატებით გაიგზავნა");
                // go back to compensations list
                history.push("/compensation/list");
              })
              .catch((error) => {
                console.log(error);
                alert(
                  (error.messages && error.messages[0]) ||
                    "გთხოვთ, სცადოთ თავიდან."
                );
              });
          }}
        >
          გაგზავნა
    </SendButton>
  )}
      </NewFormsContentBox>
      )}
    </NewFormsContainer>
  );
}
