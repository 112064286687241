import React from 'react'

// style components
import Responsive from 'config/Responsive';
import styled from 'styled-components';

// payment components
import MyCards from "../components/Payments/MyCards";
import PayMentSchedule from "../components/Payments/PaymentSchedule";
import PayMentHistory from "../components/Payments/PaymentHistory";






// custom style
const PayMentWrapper = styled.div`
    
        ${Responsive.laptop}{
          
        }
        ${Responsive.tablet}{
            
        }
        ${Responsive.mobile}{
           
        }
`;




function Payment() {
    return (
        <PayMentWrapper>
            
            <MyCards /> 
            <PayMentSchedule />
            <PayMentHistory />
        </PayMentWrapper>
    )
}

export default Payment
