import * as React from "react";

function RightArrow(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21.4 9.98"
      {...props}
    >
      <g data-name="Group 3027">
        <path
          data-name="Path 8799"
          d="M20.7 4.981H.7"
          fill="#261e56"
          stroke="#261e56"
          strokeLinecap="round"
          strokeWidth={1.4}
        />
        <g data-name="Group 2472">
          <path
            data-name="Path 9328"
            d="M16.7 8.99l4-4-4-4"
            fill="none"
            stroke="#261e56"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.4}
          />
        </g>
      </g>
    </svg>
  );
}

export default RightArrow;
