import dayjs from "dayjs";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import PinckRightBorder from "../borders/PolicyBorder";
import "../components/ReactSlider/Slider.css";
// icon
import CopyIcon from "../icons/CopyIcon";
import WatchIcon from "../icons/DateWatchIcon";
// import PolicyPlusIcon from '../icons/PlusIcon';

import BottomArrow from "../icons/PolicyCardArrow";

import { Policy } from "../domain";
import Colors from "../styles/Colors";
import Fonts from "../styles/Fonts";
import Responsive from "../config/Responsive";
import { getPolicyType, typeOfPolicy } from "domain/policyType";

// style
const FirstBox = styled.div``;
const PolicySliderContent = styled.div`
  height: fit-content;
  overflow: hidden;
  background-color: #fbfbfb;
  margin-bottom: 50px;
  ${Responsive.desktop} {
  }
  ${Responsive.laptop} {
  }
  ${Responsive.tablet} {
  }
  ${Responsive.mobile} {
  }
`;
const InsuredPersonContent = styled.div`
  height: 200px;
  background-color: #fbfbfb;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
  margin: 15px;
  /* overflow: hidden; */
  ${Responsive.desktop} {
    height: 200px;
    margin: 15px;
  }
  ${Responsive.laptop} {
    height: 140px;
    margin: 15px;
  }
  ${Responsive.tablet} {
    margin: 15px;
    height: 140px;
  }
  ${Responsive.mobile} {
    margin: 2px 10px 30px 10px;
    height: 140px;
  }
`;
const InsuredPersonName = styled.h1`
  font-size: 16px;
  color: ${Colors.TitleBlue};
  font-family: ${Fonts.FiraGORegular};
  margin: unset;
  text-align: left;
  margin-left: 31px;

  padding-top: 45px;
  cursor: default;
  ${Responsive.desktop} {
    font-size: 16px;
    margin-left: 31px;
    padding-top: 40px;
  }
  ${Responsive.laptop} {
    font-size: 14px;
    padding-top: 20px;
    margin-left: 30px;
  }
  ${Responsive.tablet} {
    font-size: 14px;
    padding-top: 20px;
    margin-left: 30px;
  }
  ${Responsive.mobile} {
    font-size: 14px;
    padding-top: 25px;
    margin-left: 35px;
  }
`;
const InsuredPersonID = styled.h1`
  font-size: 14px;
  margin: unset;
  color: ${Colors.LightGray};
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  padding-top: 20px;
  text-align: left;
  display: block;
  margin-left: 31px;
  cursor: default;
  ${Responsive.desktop} {
    font-size: 14px;
    padding-top: 20px;
    margin-left: 31px;
  }
  ${Responsive.laptop} {
    font-size: 12px;
    padding-top: 15px;
    margin-left: 30px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    padding-top: 15px;
    margin-left: 30px;
  }
  ${Responsive.mobile} {
    font-size: 12px;
    padding-top: 18px;
    margin-left: 35px;
  }
`;
const InsuredPersonDate = styled.h1`
  font-size: 13px;
  color: ${Colors.LightGray};
  font-family: ${Fonts.FiraGORegular};
  font-weight: 500;
  margin: unset;
  //padding-top: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin-left: 31px;
  cursor: default;
  ${Responsive.desktop} {
    font-size: 13px;
    padding-top: 35px;
    margin-left: 31px;
  }
  ${Responsive.laptop} {
    font-size: 12px;
    padding-top: 27px;
    margin-left: 30px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    padding-top: 26px;
    margin-left: 30px;
  }
  ${Responsive.mobile} {
    font-size: 12px;
    padding-top: 25px;
    margin-left: 35px;
  }
`;
const InsuredPersonPolicyType = styled.h1`
  font-size: 13px;
  color: ${Colors.LightGray};
  font-family: ${Fonts.FiraGORegular};
  font-weight: 500;
  margin: unset;
  padding-top: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin-left: 31px;
  cursor: default;
  ${Responsive.desktop} {
    font-size: 13px;
    padding-top: 35px;
    margin-left: 31px;
  }
  ${Responsive.laptop} {
    font-size: 12px;
    padding-top: 27px;
    margin-left: 30px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    padding-top: 26px;
    margin-left: 30px;
  }
  ${Responsive.mobile} {
    font-size: 12px;
    padding-top: 25px;
    margin-left: 35px;
  }
`;
// const CustomDotsInActive = styled.div`
//     width: 9px;
//     height: 9px;
//     background-color: #E5E6ED;
//     border-radius: 50%;
//     margin-right: 5px;
//     margin-left: 5px;
//     margin-top: 2px;
//     transition: 3s all;
//     cursor: pointer;
// `;
// const CustomDotsActive = styled.div`
//     width: 13px;
//     height: 13px;
//     background-color: ${Colors.Primary};
//     border-radius: 50%;
//     transition: 3s all;
//     margin-right: 5px;
//     margin-left: 5px;
//     cursor: pointer;
// `;
const PoliciesSliderDate = styled.h1`
  margin: unset;
  font-size: 13px;
  font-family: ${Fonts.FiraGORegular};
  color: ${Colors.LightGray};
  font-weight: 400;
  ${Responsive.tablet} {
    font-size: 12px;
  }
  ${Responsive.mobile} {
    font-size: 12px;
  }
`;
// const AddNewPolicyBox = styled.div`
//   height: 200px;
//   border: 2px dashed #767880;
//   border-radius: 6px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   margin-top: 2px;
//   margin-left: 2px;
//         ${Responsive.desktop}{
//           height: 200px;
//           border: 2px dashed #767880;
//           margin-top: 2px;
//           margin-left: 2px;
//         }
//         ${Responsive.laptop}{
//           height: 140px;
//           border: 2px dashed #767880;
//           margin-top: 2px;
//           margin-left: 2px;
//         }
// `;
// const AddNewPolicyRightFirstText = styled.h1`
//   font-size: 18px;
//   font-family: ${Fonts.FiraGOMtavruliBold};
//   color: ${Colors.Gray};
//   margin: unset;
//         ${Responsive.desktop}{
//           font-size: 18px;
//         }
//         ${Responsive.laptop}{
//           font-size: 16px;
//         }
// `;
// const AddNewPolicyRightLasttText = styled.h1`
//   font-size: 14px;
//   font-family: ${Fonts.FiraGORegular};
//   color: ${Colors.Gray};
//   margin: unset;
//   margin-top: 18px;
//   display: block;
//       ${Responsive.desktop}{
//         font-size: 14px;
//         margin-top: 18px;
//       }
//       ${Responsive.laptop}{
//         font-size: 12px;
//         margin-top: 10px;
//         font-weight: 400;
//       }
// `;

// dots props
const CustomDot = ({ onClick, ...rest }: any) => {
  const {
    // onMove,
    // index,
    active,
    carouselState: { currentSlide, deviceType, totalItems, slidesToShow },
  } = rest;
  /* carouselState: {
containerWidth: 728
​​currentSlide: 0
​​deviceType: "desktop"
​​domLoaded: true
​​itemWidth: 364
​​slidesToShow: 2
​​totalItems: 2
​​transform: -0
} */
  if (totalItems <= slidesToShow) {
    return null;
  }
  return (
    <button
      style={{ marginTop: 10 }}
      className={active ? "active" : "inactive"}
      onClick={() => onClick()}
    ></button>
  );
};

const PinckRightBG = styled(PinckRightBorder)`
  height: 110px;
  width: 140px;
  position: absolute;
  bottom: -1px;
  right: -1px;
  cursor: pointer;
  ${Responsive.desktop} {
    height: 110px;
    width: 140px;
    bottom: -1px;
    right: -1px;
  }
  ${Responsive.laptop} {
    height: 77px;
    width: 98px;
    bottom: -1px;
    right: -1px;
  }
  ${Responsive.tablet} {
    height: 77px;
    width: 98px;
    bottom: -1px;
    right: -1px;
  }
  ${Responsive.mobile} {
    height: 77px;
    width: 98px;
    bottom: -2px;
    right: -1px;
  }
`;
const CopyIconJs = styled(CopyIcon)`
  margin-left: 10px;
  height: 15px;
  width: fit-content;
  color: ${Colors.Gray};
  cursor: pointer;
  ${Responsive.desktop} {
    margin-left: 10px;
    height: 15px;
  }
  ${Responsive.laptop} {
    margin-left: 10px;
    height: 12px;
  }
  ${Responsive.tablet} {
    margin-left: 8px;
    height: 12px;
  }
  ${Responsive.mobile} {
    margin-left: 8px;
    height: 12px;
  }
`;
const BottomArrowJs = styled(BottomArrow)`
  width: 24px;
  position: absolute;
  right: 48px;
  bottom: 48px;
  ${Responsive.desktop} {
    width: 24px;
    right: 48px;
    bottom: 48px;
  }
  ${Responsive.laptop} {
    width: 16px;
    right: 33px;
    bottom: 30px;
  }
  ${Responsive.tablet} {
    width: 16px;
    right: 33px;
    bottom: 30px;
  }
  ${Responsive.mobile} {
    width: 16px;
    right: 33px;
    bottom: 30px;
  }
`;
const DateWatchIconJs = styled(WatchIcon)`
  margin-right: 10px;
  height: 15px;
  margin-top: -1px;
  width: 10px;
  ${Responsive.desktop} {
    margin-right: 10px;
    height: 15px;
    margin-top: -1px;
  }
  ${Responsive.laptop} {
    margin-right: 8px;
    height: 11px;
    margin-top: 1px;
  }
  ${Responsive.tablet} {
    margin-right: 8px;
    height: 12px;
    margin-top: -0.5px;
  }
  ${Responsive.mobile} {
    margin-right: 8px;
    height: 12px;
    margin-top: -0.5px;
  }
`;

const PoliciesSlider = ({ policies }: { policies: Policy[] }) => {
  /** 2 slides (for 1-2 items) or 3 (for >=3 items) */
  const slidesOnDesktop = Math.min(3, Math.max(2, policies.length));
  return (
    <PolicySliderContent>
      <Carousel
        responsive={{
          fullHD: {
            breakpoint: { min: 1920, max: 3000 },
            items: slidesOnDesktop,
            slidesToSlide: slidesOnDesktop, // optional, default to 1.
            /* partialVisible: true,
             * partialVisibilityGutter: 40, */
          },
          desktop: {
            breakpoint: { min: 1024, max: 1919 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
            /* partialVisible: true,
             * partialVisibilityGutter: 40, */
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 2, // optional, default to 1.
            /* partialVisible: true,
             * partialVisibilityGutter: 40, */
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
            /* partialVisible: true,
             * partialVisibilityGutter: 40, */
          },
        }}
        autoPlay={false}
        arrows={false}
        showDots={true}
        customDot={<CustomDot />}
        renderButtonGroupOutside={true}
        partialVisible={false}
      >
        {policies.map((policy, index) => {
          const policyType = typeOfPolicy(policy);
          const Icon = policyType.icon;
          return (
            <FirstBox key={index}>
              <InsuredPersonContent key={policy.policyNumber}>
                <InsuredPersonName style={{display: 'block',position: 'relative'}}>
                  {policy.objectName || "უცნობი დაზღვეული ობიექტი"}

                  <InsuredPersonDate style={{position: 'absolute', right: 15,top:0}}>
                    <DateWatchIconJs />
                    <PoliciesSliderDate>
                      {dayjs(policy?.endsAt).format("MM/DD")}
                    </PoliciesSliderDate>
                  </InsuredPersonDate>
                </InsuredPersonName>

                <InsuredPersonID>
                  <span style={{ marginRight: 7 }}>N</span>
                  {policy.policyNumber}

                  {policy?.policyNumber && (
                    <CopyToClipboard
                      text={policy.policyNumber}
                      onCopy={() => alert("წარმატებით დაკოპირდა")}
                    >
                      <CopyIconJs />
                    </CopyToClipboard>
                  )}
                </InsuredPersonID>

                    <Link to={`/policy-details/${policy.policyPolid}`}>
                        <PinckRightBG />
                        <BottomArrowJs />
                    </Link>

                <Link to={`/policy-details/${policy.policyPolid}`}>
                  <PinckRightBG />
                  <BottomArrowJs />
                </Link>
              </InsuredPersonContent>
            </FirstBox>
          );
        })}

        {/* <AddNewPolicyBox>
                    <div>
                        <PolicyPlusIcon  size={18}  style={{ color: Colors.Gray, marginRight: 30}} />
                    </div>
                    <div>
                          <AddNewPolicyRightFirstText>პოლისის დამატება</AddNewPolicyRightFirstText>
                          <AddNewPolicyRightLasttText>ახალი პოლისის შეძენა</AddNewPolicyRightLasttText>
                    </div>
                </AddNewPolicyBox> */}
      </Carousel>
    </PolicySliderContent>
  );
};

// ReactDOM.render(<HealthPolicy />, document.querySelector('.demo-carousel'));

export default PoliciesSlider;
