import React from 'react'
import styled from 'styled-components';
import Fonts from 'styles/Fonts';



const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #636381;
  font-size: 16px;
  user-select: none;
  font-weight: 400;
  font-family: ${Fonts.FiraGOMtavruliBold};
  height: 30vh;
`;

function InformationNotFound() {
    return (
        <NotFound>
            ინფორმაცია არ მოიძებნა
        </NotFound>
    )
}

export default InformationNotFound
