import * as React from "react";

function FinishedCheck(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 10"
      {...props}
    >
      <path
        data-name="Path 9525"
        d="M13.626 2.08L6.57 9.643a1.319 1.319 0 01-1.8 0l-4.392-3.6a1.18 1.18 0 010-1.723 1.319 1.319 0 011.8 0l3.49 2.742 6.153-6.7a1.319 1.319 0 011.8 0 1.181 1.181 0 01.005 1.718z"
        fill="#62ccda"
      />
    </svg>
  );
}

export default FinishedCheck;
