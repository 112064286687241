import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import AuthLayout from "./components/layouts/AuthLayout";
import Layout from "./components/layouts/Layout";
import NotFound from "./pages/NotFound";
import PrivateRoute from "./router/PrivateRoute";
import Login from "./screens/Auth/Login";
import Register from "./screens/Auth/Register";
import RegisterStepThree from "./screens/Auth/RegisterStepThree";
import RegisterStepTwo from "./screens/Auth/RegisterStepTwo";
import ResetPassword from "./screens/Auth/ResetPassword";
import ResetPasswordStepThree from "./screens/Auth/ResetPasswordStepThree";
import ResetPasswordStepTwo from "./screens/Auth/ResetPasswordStepTwo";
import Dashboard from "./screens/Dashboard";
import DoctorsList from "./screens/DoctorsAndProviderClinics/DoctorsList";
import SearchFamilyDoctor from "./screens/DoctorsAndProviderClinics/SearchFamilyDoctor";

import AddVisitWithDoctor from "./screens/DoctorsAndProviderClinics/AddVisit";

import Appeals from "./screens/Appeals";
import TravelPolicyDetail from "./screens/Policies/TravelPolicyDetail";
import PolicyLimits from "./screens/Policies/PolicyLimits";

// claims (საგარანტიო მიმართვები)
import ClaimList from "./screens/Claims/ClaimList";
import ClaimDetails from "./screens/Claims/ClaimDetails";
import ClaimHistoryDetails from "./screens/Claims/ClaimHistoryDetails";
import AddClaim from "./screens/Claims/AddClaim";
// compensations (ანაზღაურებები)
import CompensationList from "./screens/Compensations/CompensationList";
import AddCompensation from "./screens/Compensations/AddCompensation";
import CompensationDetails from "./screens/Compensations/CompensationDetails";
import CompensationHistoryDetails from "./screens/Compensations/CompensationHistoryDetails";
//

import NewSideBar from "./components/NewSideBar";
import UserNotification from "./screens/User/Notifications";

import VisitsList from "./screens/DoctorsAndProviderClinics/VisitsList";
import FilteringClinics from "./screens/DoctorsAndProviderClinics/FilteringClinics";
import InsuranceCategory from "./screens/Policies/InsuranceCategory";
import MyPoliciesDetails from "./screens/Policies/MyPoliciesDetails";

import ListOfPoliciesForSale from "./screens/ReferencesAndCompensation/ListOfPoliciesForSale";

import PurchaseOfAuto from "./screens/ReferencesAndCompensation/PurchaseOfAuto";

import Profile from "./screens/User/Profile";
import { useAppDispatch } from "./config/store";
import setAuthToken from "./utils/setAuthToken";

import { useSelector } from "react-redux";
import { RootState } from "./slices";
import { AuthState, InsuranceState } from "./domain";

// ???
import WarrantyCurrentPolicy from "./screens/WarrantyCurrentPolicy";
import WarrantyCompletedPolicy from "./screens/WarrantyCompletedPolicy";
import CompletedPolicy from "./components/NewForms/CompletedPolicy";
import PurchaseOfPolicy from "./screens/Policies/PurchaseOfPolicy";
// ???
import ReferencesDetailPage from "./screens/ReferencesAndCompensation/ReferencesDetailPage";
import ReferencesList from "./screens/ReferencesAndCompensation/ReferencesList";
// import Compensation from "./screens/ReferencesAndCompensation/Compensation";
import MyCompensation from "./screens/ReferencesAndCompensation/MyCompensation";
import NewReferences from "./screens/ReferencesAndCompensation/NewReferences";
import Payment from "screens/Payment";

import './assets/fonts/fonts.css';

import { loadUser } from "./slices/authSlice";
import {
  getPolicies,
  getDoctors,
  getProviders,
  getClaims,
  getCompensations,
  getClaimHistory,
  getCompensationHistory,
  getVisits,
} from "./slices/insuranceSlice";

const storedToken = localStorage.getItem("token");
if (storedToken) {
  setAuthToken(storedToken);
}

const App: React.FC = () => {
  const { token: tokenInRedux } = useSelector<RootState, AuthState>(
    (data) => data.auth
  );
  const {
    policies,
    policiesLoading,
    activeMedicalPolicy,
    doctors,
    doctorsLoading,
    providers,
    providersLoading,
    visits,
    visitsLoading,
    claims,
    claimsLoading,
    compensations,
    compensationsLoading,
    claimHistory,
    claimHistoryLoading,
    compensationHistory,
    compensationHistoryLoading,
  } = useSelector<RootState, InsuranceState>((data) => data.insurance);

  const tokenStoredOrSetAfterLogin = storedToken || tokenInRedux;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (tokenStoredOrSetAfterLogin) {
      // Autoload user from LocalStorage
      dispatch(loadUser());
      if (!policies.length) {
        dispatch(getPolicies(null));
      }
      if (!doctors.length) {
        dispatch(getDoctors(null));
      }
      if (!providers.length) {
        dispatch(getProviders(null));
      }
      if (!claims.length) {
        dispatch(getClaims(null));
      }
      if (!compensations.length) {
        dispatch(getCompensations(null));
      }
      if (!visits.length) {
        dispatch(getVisits(null));
      }
      //
    }
    return () => {};
  }, [dispatch, tokenInRedux]);
  useEffect(() => {
    if (activeMedicalPolicy) {
      dispatch(
        getClaimHistory({ policyPolid: activeMedicalPolicy.policyPolid })
      );
      dispatch(
        getCompensationHistory({ policyPolid: activeMedicalPolicy.policyPolid })
      );
    }
  }, [activeMedicalPolicy]);
  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Route path="/login">
          <AuthLayout>
            <Login />
          </AuthLayout>
        </Route>

        <Route path="/register">
          <AuthLayout>
            <Register />
          </AuthLayout>
        </Route>
        <Route path="/register-step-two">
          <AuthLayout>
            <RegisterStepTwo />
          </AuthLayout>
        </Route>
        <Route path="/register-step-three">
          <AuthLayout>
            <RegisterStepThree />
          </AuthLayout>
        </Route>

        <Route path="/reset-password">
          <AuthLayout>
            <ResetPassword />
          </AuthLayout>
        </Route>

        <Route path="/reset-password-step-two">
          <AuthLayout>
            <ResetPasswordStepTwo />
          </AuthLayout>
        </Route>

        <Route path="/reset-password-step-three">
          <AuthLayout>
            <ResetPasswordStepThree />
          </AuthLayout>
        </Route>
        <PrivateRoute
          path="/new-side-bar"
          // wrapper={Layout}
          component={NewSideBar}
        />
        <PrivateRoute
          path="/dashboard"
          wrapper={Layout}
          component={Dashboard}
        />
        <PrivateRoute path="/profile/:tab" wrapper={Layout} component={Profile} />
        <PrivateRoute path="/profile" wrapper={Layout} component={Profile} />
        <PrivateRoute
          path="/user-notification"
          wrapper={Layout}
          component={UserNotification}
        />
        <PrivateRoute
          path="/my-policies/:policyTypeKeyword"
          wrapper={Layout}
          component={InsuranceCategory}
        />
        <PrivateRoute
          path="/policy-details/:policyPolid"
          wrapper={Layout}
          component={MyPoliciesDetails}
        />
        <PrivateRoute path="/appeals" wrapper={Layout} component={Appeals} />
        <PrivateRoute
          path="/warranty-completed-policy"
          wrapper={Layout}
          component={WarrantyCompletedPolicy}
        />
        <PrivateRoute
          path="/purchase-of-policy"
          wrapper={Layout}
          component={PurchaseOfPolicy}
        />
        <PrivateRoute
          path="/travel-policy-detail"
          wrapper={Layout}
          component={TravelPolicyDetail}
        />
        {/*  */}
        <PrivateRoute
          path="/warranty-current-policy"
          wrapper={Layout}
          component={WarrantyCurrentPolicy}
        />
        <PrivateRoute
          path="/claim/list"
          wrapper={Layout}
          component={ClaimList}
        />
        <PrivateRoute
          path="/claim/details/:claimId"
          wrapper={Layout}
          component={ClaimDetails}
        />
        <PrivateRoute
          path="/claim/history/details/:documentNumber"
          wrapper={Layout}
          component={ClaimHistoryDetails}
        />
        <PrivateRoute path="/claim/add" wrapper={Layout} component={AddClaim} />
        <PrivateRoute
          path="/compensation/list"
          wrapper={Layout}
          component={CompensationList}
        />
        <PrivateRoute
          path="/compensation/details/:compensationId"
          wrapper={Layout}
          component={CompensationDetails}
        />
        <PrivateRoute
          path="/compensation/history/details/:documentNumber"
          wrapper={Layout}
          component={CompensationHistoryDetails}
        />
        <PrivateRoute
          path="/compensation/add"
          wrapper={Layout}
          component={AddCompensation}
        />
        <PrivateRoute
          path="/visit/list"
          wrapper={Layout}
          component={VisitsList}
        />
        <PrivateRoute
          path="/completed-policy"
          wrapper={Layout}
          component={CompletedPolicy}
        />
        <PrivateRoute
          path="/references-list"
          wrapper={Layout}
          component={ReferencesList}
        />
        <PrivateRoute
          path="/references-Detail-Page"
          wrapper={Layout}
          component={ReferencesDetailPage}
        />
        <PrivateRoute
          path="/new-references"
          wrapper={Layout}
          component={NewReferences}
        />
        <PrivateRoute
          path="/policy-limits/:policyPolid"
          wrapper={Layout}
          component={PolicyLimits}
        />
        <PrivateRoute
          path="/my-compensation"
          wrapper={Layout}
          component={MyCompensation}
        />
        <PrivateRoute
          path="/compensation-list"
          wrapper={Layout}
          component={CompensationList}
        />
        <PrivateRoute
          path="/new-compensation"
          wrapper={Layout}
          component={AddCompensation}
        />
        <PrivateRoute
          path="/List-Of-Policies-For-Sale"
          wrapper={Layout}
          component={ListOfPoliciesForSale}
        />
        <PrivateRoute
          path="/Purchase-Of-Auto"
          wrapper={Layout}
          component={PurchaseOfAuto}
        />
        <PrivateRoute
          path="/doctor/list"
          wrapper={Layout}
          component={DoctorsList}
        />
        <PrivateRoute
          path="/search-Family-doctor"
          wrapper={Layout}
          component={SearchFamilyDoctor}
        />
        <PrivateRoute
          path="/visit/add"
          wrapper={Layout}
          component={AddVisitWithDoctor}
        />
        <PrivateRoute
          path="/Filtering-Clinics"
          wrapper={Layout}
          component={FilteringClinics}
        />
        <PrivateRoute path="/payment" wrapper={Layout} component={Payment} />

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};

// TODO restore PrivateRoute for password-protected screens
{
  /* <Router>
<Header />
<Switch>
</Switch>
</Router> */
}

export default App;
