import * as React from "react";

function ResponsibilityIcon(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 60 52"
      {...props}
    >
      <g data-name="Group 4870">
        <path
          data-name="Intersection 102"
          d="M10 52A10 10 0 010 42V10A10 10 0 0110 0h50l-7.845 24.71L46.9 41.26a16.838 16.838 0 01-2.184 4.788 13.246 13.246 0 01-8.793 5.828 11.505 11.505 0 01-1.768.124z"
          fill="#ebe8fc"
        />
        <g data-name="Group 4607">
          <path
            data-name="Path 8578"
            d="M42.447 26.002a6.772 6.772 0 004.949-2.05 6.772 6.772 0 002.05-4.95 6.773 6.773 0 00-2.05-4.949 7 7 0 00-9.9 0 6.772 6.772 0 00-2.048 4.949 6.772 6.772 0 002.051 4.949 6.774 6.774 0 004.948 2.051zm0 0"
            fill="#261e56"
          />
          <path
            data-name="Path 8579"
            d="M55.135 33.518a15.554 15.554 0 00-.245-1.772 13.146 13.146 0 00-.469-1.782 8.5 8.5 0 00-.789-1.662 6.352 6.352 0 00-1.189-1.439 5.316 5.316 0 00-1.709-1 29.674 29.674 0 00-8.252-1.266 28.381 28.381 0 00-8.022 1.267 5.311 5.311 0 00-1.709 1 6.35 6.35 0 00-1.189 1.439 8.51 8.51 0 00-.789 1.662 13.175 13.175 0 00-.469 1.781 15.5 15.5 0 00-.245 1.773c-.04.537-.06 1.094-.06 1.656a4.488 4.488 0 001.489 3.523 5.607 5.607 0 003.838 1.3h14.543a5.606 5.606 0 003.838-1.3 4.486 4.486 0 001.493-3.525 21.7 21.7 0 00-.065-1.655zm0 0"
            fill="#261e56"
          />
          <path
            data-name="Intersection 82"
            d="M30.302 31.745a13.191 13.191 0 01.469-1.781 8.482 8.482 0 01.793-1.662 6.312 6.312 0 011.189-1.439 5.316 5.316 0 011.708-1 27.457 27.457 0 014.208-.96 7.389 7.389 0 01-1.17-.957 6.773 6.773 0 01-2.051-4.944 6.773 6.773 0 012.054-4.951 6.769 6.769 0 014.945-2.049 6.77 6.77 0 014.948 2.05l.115.118a23.38 23.38 0 01-17.408 18.905c.045-.422.114-.867.2-1.33z"
            fill="#ff677f"
          />
          <path
            data-name="Path 12879"
            d="M51.052 29.694a3.287 3.287 0 00-2.231-1.188"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.6}
          />
        </g>
      </g>
    </svg>
  );
}

export default ResponsibilityIcon;
