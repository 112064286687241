import { Reducer, CombinedState, AnyAction, combineReducers } from 'redux';
import authReducer, { logoutUser, authInitialStateForMocking } from './authSlice';
import insuranceReducer, { insuranceInitialStateForMocking } from './insuranceSlice';

// rootReducer
export const combinedReducer = combineReducers({
  auth: authReducer,
  insurance: insuranceReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

export const rootReducer: Reducer = (
  state: RootState,
  action: AnyAction
) => {
  /* clear all data on logout */
  if (action.type === logoutUser.type) {
    state = {
      auth: authInitialStateForMocking,
      insurance: insuranceInitialStateForMocking,
    } as RootState;
  }

  return combinedReducer(state, action);
};


