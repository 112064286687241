import * as React from "react";

function DefaultStar(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 18"
      {...props}
    >
      <path
        d="M18.588 8.153a1.329 1.329 0 00.345-1.381 1.35 1.35 0 00-1.1-.917l-4.6-.661a.6.6 0 01-.453-.325L10.722.753a1.369 1.369 0 00-2.444 0l-2.056 4.12a.6.6 0 01-.453.325l-4.6.661a1.35 1.35 0 00-1.1.917 1.329 1.329 0 00.344 1.377l3.328 3.213a.592.592 0 01.173.527l-.785 4.53a1.309 1.309 0 00.3 1.093 1.388 1.388 0 001.68.328l4.114-2.139a.622.622 0 01.56 0l4.115 2.139a1.366 1.366 0 001.679-.328 1.308 1.308 0 00.3-1.093l-.786-4.53a.591.591 0 01.173-.527z"
        fill="#767880"
      />
    </svg>
  );
}

export default DefaultStar;
