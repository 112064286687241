import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import AccordionRightArrow from "../icons/AccordionRightArrow";
import AccordionLeftIcon from "../icons/AccordionLeftIcon";

import styled from 'styled-components';
import Fonts from 'styles/Fonts';
import Colors from 'styles/Colors';
import Responsive from 'config/Responsive';

import "../components/Header";


const AccordionIcon = styled(AccordionRightArrow)`
    transform: rotate(180deg);
    width: 12px;
        ${Responsive.laptop}{
            width: 10px;
            height: 10px;
        }
        ${Responsive.tablet}{
            width: 10px;
            height: 10px;
        }
        ${Responsive.mobile}{
            width: 10px;
            height: 10px;
        }
`;
const AccordionLeftDecore = styled(AccordionLeftIcon)`
    width: 10px;
    height: 13px;
    /* margin-right: 13px; */
    display: inline-block;
`;

const AccordionMainTitle = styled.h1`
    font-size: 18px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    color: ${Colors.TitleBlue};
    margin: unset;
    margin-bottom: 40px;
        ${Responsive.laptop}{
            font-size: 16px;
            margin-bottom: 30px;
        }
        ${Responsive.tablet}{
            font-size: 16px;
            margin-bottom: 30px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
            margin-bottom: 25px;
        }
`;
const AccordionTitle = styled.h1`
    font-size: 16px;
    font-family: ${Fonts.FiraGOMtavruliRegular};
    font-weight: 400;
    margin: unset;
    color: #767880;
    display: block;
    margin-left: 20px;
    margin-top: -13px;
        ${Responsive.laptop}{
            font-size: 14px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
        }
`;
const AccordionListTitle = styled.h1`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    color: #767880;
    margin: unset;
    line-height: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
    user-select: none;
    display: block;
        ${Responsive.laptop}{
            font-size: 12px;
            line-height: 16px;
            padding-top: 7px;
            padding-bottom: 7px;
        }
        ${Responsive.tablet}{
            font-size: 12px;
            line-height: 16px;
            padding-top: 7px;
            padding-bottom: 7px;
         
        }
        ${Responsive.mobile}{
            font-size: 12px;
            line-height: 16px;
            padding-top: 7px;
            padding-bottom: 7px;
         
        }
`;









const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

export default function SimpleAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>

    <AccordionMainTitle>რას მოიცავს დაზღვევა</AccordionMainTitle>

      <Accordion>
        <AccordionSummary
          expandIcon={<AccordionIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography className={classes.heading}><AccordionLeftDecore /><AccordionTitle>რა ტიპის სატრანსპორტო საშუალები შეგიძლია დააზღვიო?</AccordionTitle></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <AccordionListTitle>სადაძღვევო პირობების ჩამონათვალი</AccordionListTitle>
            <AccordionListTitle>სადაძღვევო პირობების ჩამონათვალი</AccordionListTitle>
            <AccordionListTitle>სადაძღვევო პირობების ჩამონათვალი</AccordionListTitle>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<AccordionIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header">
          <Typography className={classes.heading}><AccordionLeftDecore /><AccordionTitle>რა დანიშნულებით შეგიძლია დააზღვიო სატრანსპორტო საშუალება?</AccordionTitle></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <AccordionListTitle>სადაძღვევო პირობების ჩამონათვალი</AccordionListTitle>
            <AccordionListTitle>სადაძღვევო პირობების ჩამონათვალი</AccordionListTitle>
            <AccordionListTitle>სადაძღვევო პირობების ჩამონათვალი</AccordionListTitle>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<AccordionIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header">
          <Typography className={classes.heading}><AccordionLeftDecore /><AccordionTitle>რა შემთხვევები ანაზღაურდება?</AccordionTitle></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <AccordionListTitle>სადაძღვევო პირობების ჩამონათვალი</AccordionListTitle>
            <AccordionListTitle>სადაძღვევო პირობების ჩამონათვალი</AccordionListTitle>
            <AccordionListTitle>სადაძღვევო პირობების ჩამონათვალი</AccordionListTitle>
          </Typography>
        </AccordionDetails>
      </Accordion>



    </div>
  );
}
