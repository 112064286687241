import * as React from "react";

function ClaimLoss(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 29.001"
      {...props}
    >
      <g data-name="doc 1">
        <path
          data-name="Path 9701"
          d="M25.717 7.2L18.728.3A1.042 1.042 0 0018 0H2.762A2.7 2.7 0 000 2.62v23.761A2.7 2.7 0 002.762 29h20.476A2.7 2.7 0 0026 26.381V7.87a.937.937 0 00-.283-.667z"
          fill="#261e56"
        />
        <path
          data-name="Path 9702"
          d="M17.64 4.276v3.692h3.762"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.4}
        />
      </g>
    </svg>
  );
}

export default ClaimLoss;
