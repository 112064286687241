import React, { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import Colors from '../styles/Colors';
import Fonts from '../styles/Fonts';

import { useSelector } from "react-redux";
// import { getPolicies, getClaims, getClaimHistory } from "../slices/insuranceSlice";
import { RootState } from "../slices";
import { InsuranceState } from "../domain";
// import { useAppDispatch } from "../config/store";
import openPolicyPaymentUrl from 'utils/openPolicyPaymentUrl';
import { Policy } from '../domain';
import Responsive from '../config/Responsive';


const PolicyPaymentModalStart = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const PolicyPaymentModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 45%;
    width: 40%;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;
      ${Responsive.laptop}{
        height: 50%;
        width: 45%;
      }
      ${Responsive.tablet}{
        height: 50%;
        width: 60%;
      }
      ${Responsive.mobile}{
        height: 40%;
        width: 94%;
        // border-bottom-left-radius: unset;
        // border-bottom-right-radius: unset;
      }
`;
const PolicyPaymentModalTitle = styled.h1`
    font-size: 18px;
    color: ${Colors.TitleBlue};
    margin: unset;
    font-family: ${Fonts.FiraGOMtavruliBold};
    font-style: normal;
    font-weight: 400;
`;
const CustomPayButton = styled.button`
    max-width: 300px;
    min-width: 100px;
    width: 100%;
    height: 50px;
    outline: none;
    border: unset;
    background-color: ${Colors.Primary};
    cursor: pointer;
    font-family: ${Fonts.FiraGOMtavruliBold};
    font-size: 16px;
    color: #fff;
    border-radius: 6px;
        &:focus,
        &:hover{
            outline: none;
            background-color: ${Colors.HoverRed};
        }
`;

const styles = {
  select: {
    width: '100%',
    maxWidth: 300,
    marginTop: 45,
    marginBottom: 30,
  },
  menuList: {
    backgroundColor: 'red'
  }
}
const customStyles = {
  option: (provided: any, state: { isSelected: any; }) => ({
    ...provided,
    //   height: 45,
    fontFamily: 'DejaVu_sans',
    ':hover': {
      backgroundColor: '#261e56',
      color: '#fff',
    },
    cursor: 'pointer',
    color: state.isSelected ? '#fff' : '#636381',
    backgroundColor: state.isSelected ? '#261e56' : 'white',
  }),

  control: (provided: any) => ({
    ...provided,
    height: 50,
    border: '1px solid #E5E6ED',
    borderRadius: 6,
    boxShadow: 'unset',
    cursor: 'pointer',
    fontFamily: 'DejaVu_sans',
    ':hover': {
      border: '1px solid #E5E6ED',
    },
  })

}
//   close
const ClosePopUpBox = styled.button`
    position: absolute;
    right: 25px;
    top: 20px;
    padding: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    padding: 14.5px;
    border: unset;
    background-color: transparent;
        &:focus{
            outline: none;
        }
`;
const ClosePopLeftLine = styled.span`
    height: 20px;
    width: 2px;
    border-radius: 10px;
    background: #767880;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    right: 13px;
    top: 5px;
`;
const ClosePopRightLine = styled.span`
    height: 20px;
    width: 2px;
    border-radius: 10px;
    background: #767880;
    display: block;
    position: absolute;
    transform: rotate(-45deg);
    right: 13px;
    top: 5px;
`;
const AmountPayable = styled.span`
    font-family: ${Fonts.FiraGORegular};
    font-size: 16px;
    color: #636381;
`;
const Debt = styled.span`
    font-family: ${Fonts.FiraGORegular};
    font-size: 16px;
    // color: #636381;
`;


const PolicyPaymentModal = ({ onClose }: { onClose: () => void }) => {
  //const [close, setClose] = React.useState(true);
  //const onClick = () => setClose(false);

  const {
    policies,
    // policiesLoading,
    // claims,
    // claimsLoading,
    // claimHistory = [],
    // claimHistoryLoading,
  } = useSelector<RootState, InsuranceState>((data) => data.insurance);

  const [policyNumber, setSelectedPolicyNumber] = useState("");

  const options = policies.map((p: Policy) => ({
    value: p.policyNumber, label: `${p.objectName || 'უცნობია'} – ${p.amountDueToday}₾ (პოლისი ${p.policyNumber})`,
  }));

  const selectedPolicy = policies.find(p => p.policyNumber == policyNumber);

  return (
    <div>
      { true ?
        <PolicyPaymentModalStart>

          <PolicyPaymentModalContent>

            <ClosePopUpBox onClick={onClose}>
              <ClosePopLeftLine />
              <ClosePopRightLine />
            </ClosePopUpBox>

            <PolicyPaymentModalTitle>აირჩიე პოლისი</PolicyPaymentModalTitle>

            <div style={styles.select}>
              <Select options={options} placeholder='აირჩიე პოლისი' styles={customStyles} onChange={(option: { value: string; label: string } | null) => option && setSelectedPolicyNumber(option.value)} />
            </div>

            {selectedPolicy && (
              <div style={{ marginBottom: 15 }}>
                {selectedPolicy.amountDueToday > 3 ? (
                  <AmountPayable>გადასახდელი თანხა: {selectedPolicy.amountDueToday} ₾</AmountPayable>
                ) : (
                    <Debt>პოლისზე არ ფიქსირდება დავალიანება</Debt>
                  )}
              </div>
            )}

            {selectedPolicy && selectedPolicy.amountDueToday > 3 && (
              <CustomPayButton disabled={!policyNumber || (!selectedPolicy || selectedPolicy.amountDueToday < 3)} onClick={() => openPolicyPaymentUrl(policyNumber)}>პრემიის გადახდა</CustomPayButton>
            )}

          </PolicyPaymentModalContent>
        </PolicyPaymentModalStart>
        : null}
    </div>
  );
}

export default PolicyPaymentModal;

