import Responsive from 'config/Responsive';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';
import Colors from 'styles/Colors';
import Fonts from 'styles/Fonts';


const GrayLinePayMent = styled.div`
  height: 1px;
  width: 100%;
  background-color: #767880;
  margin-left: 30px;
  padding-right: 50px;
  margin-top: 20px;
    ${Responsive.laptop}{
      margin-left: 20px;
    }
    ${Responsive.tablet}{
      margin-left: 20px;
    }
    ${Responsive.mobile}{
      margin-left: unset;
    }
`;



const PayMentScheduleContainer = styled.div`
    width: 100%;
    box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
    border-radius: 10px;
    margin-top: 40px;
        ${Responsive.laptop}{
            margin-top: 25px;
        }
        ${Responsive.tablet}{
            margin-top: 25px;
        }
        ${Responsive.mobile}{
            margin-top: 25px;
        }
`;
const PayMentScheduleWrapper = styled.div`
    padding: 50px;
    display: flex;
    align-items: center;
        ${Responsive.laptop}{
          padding: 30px;
        }
        ${Responsive.tablet}{
          padding: 20px;
        }
        ${Responsive.mobile}{
          padding: 30px;
          flex-direction: column;
          position: relative;
        }
`;
const PayMentScheduleChart = styled.div`

`;
const PayMentScheduleTitlesWrapper = styled.div`

`;
const PayMentScheduleTitle = styled.h1`
    font-size: 18px;
    font-family: ${Fonts.FiraGOMtavruliBold};
    font-weight: 400;
    margin: unset;
    user-select: none;
        ${Responsive.laptop}{
          font-size: 14px;
        }
        ${Responsive.tablet}{
          font-size: 14px;
        }
        ${Responsive.mobile}{
          font-size: 14px;
          text-align: left;
        }
`;
const PayMentScheduleInfoButtoWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 30px;
        ${Responsive.laptop}{
          margin-top: 30px;
          margin-bottom: 20px;
        }
        ${Responsive.tablet}{
          margin-top: 30px;
          margin-bottom: 20px;
        }
        ${Responsive.mobile}{
          flex-direction: column;
          align-items: flex-start;
          margin-top: 100px;
          margin-bottom: 10px;
        }
`;
const PayMentScheduleInfoButto = styled.div`
    font-size: 14px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    margin: unset;
    color: #767880;
    user-select: none;
    display: flex;
    align-items: center;
        ${Responsive.laptop}{
          font-size: 12px;
        }
        ${Responsive.tablet}{
          font-size: 12px;
        }
        ${Responsive.mobile}{
          font-size: 12px;
          line-height: 25px;
        }
`;
const PayMentScheduleInfoButtoSpan = styled.span`
    width: 10px;
    height: 5px;
    border-radius: 3px;
    display: inline-block;
    margin-right: 10px;
`;
const PayMentScheduleFlexOne = styled.div`
    flex-basis: 65%;
        ${Responsive.tablet}{
          flex-basis: 60%;
        }
        ${Responsive.mobile}{
          flex-basis: unset;
        }
`;
const PayMentScheduleFlexTwo = styled.div`
    flex-basis: 35%;
    overflow: hidden;
        ${Responsive.tablet}{
          flex-basis: 40%;
        }
        ${Responsive.mobile}{
          flex-basis: unset;
          width: 100%;
        }
`;
const PayMentScheduleFlexTwoSelect = styled.select`
    width: 50%;
    height: 50px;
    border-radius: 6px;
    border: 1px solid #E5E6ED;
    padding-left: 20px;
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    font-weight: 400;
    color: ${Colors.TitleBlue};
    margin: 0 0 0 auto;
    display: block;
      &:focus,
      &:hover{
          outline: none;
      }
    ${Responsive.laptop}{
      width: 60%;
      height: 42px;
      border-radius: 4px;
      padding-left: 15px;
      font-size: 12px;
    }
    ${Responsive.tablet}{
      width: 60%;
      height: 42px;
      border-radius: 4px;
      padding-left: 15px;
      font-size: 12px;
    }
    ${Responsive.mobile}{
      position: absolute;
      top: 80px;
      left: 30px;
      height: 38px;
      border-radius: 4px;
      font-size: 12px;
    }
`;
const PolicyCostPayMentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
  margin-left: 30px;
  border-bottom: 1px solid #767880;
  padding-bottom: 20px;
    ${Responsive.laptop}{
      margin-top: 60px;
      margin-left: 20px;
      padding-bottom: 20px;
    }
    ${Responsive.tablet}{
      margin-top: 60px;
      margin-left: 20px;
      padding-bottom: 20px;
    }
    ${Responsive.mobile}{
      margin-left: unset;
      margin-top: 50px;
    }
`;
const PolicyCostPayMent = styled.h1`
  margin: unset;
  font-size:  14px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  color: #767880;
    ${Responsive.laptop}{
      font-size:  12px;
    }
    ${Responsive.tablet}{
      font-size:  12px;
    }
    ${Responsive.mobile}{
      font-size:  12px;
    }
`;
const PolicyCostPayMentAnswer = styled.h1`
  margin: unset;
  font-size:  14px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  color: #636381;
    ${Responsive.laptop}{
      font-size:  12px;
    }
    ${Responsive.tablet}{
      font-size:  12px;
    }
    ${Responsive.mobile}{
      font-size:  12px;
    }
`;
const TotalPayablePayMentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-left: 30px;
    ${Responsive.laptop}{
      margin-top: 10px;
      padding-left: 20px;
    }
    ${Responsive.tablet}{
      margin-top: 10px;
      padding-left: 20px;
    }
    ${Responsive.mobile}{
      padding-left: unset;
    }
`;
const TotalPayablePayMent = styled.h1`
  margin: unset;
  font-size:  14px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  color: #767880;
    ${Responsive.laptop}{
      font-size:  12px;
    }
    ${Responsive.tablet}{
      font-size:  12px;
    }
    ${Responsive.mobile}{
      font-size:  12px;
    }
`;
const TotalPayablePayMentAnswer = styled.h1`
  margin: unset;
  font-size: 14px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  color: #44C2D3;
    ${Responsive.laptop}{
      font-size:  12px;
    }
    ${Responsive.tablet}{
      font-size:  12px;
    }
    ${Responsive.mobile}{
      font-size:  12px;
    }
`;

const data = {
  labels: ['იან', 'თებ', 'მარ', 'აპრ', 'მაი', 'ივნ', 'ივლ', 'აგვ', 'სექ', 'ოქტ', 'ნოე', 'დეკ'],
  datasets: [
    {
      label: '',
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,12],
      backgroundColor: [
        '#62CCDA',
        '#62CCDA',
        '#62CCDA',
        '#62CCDA',
        '#62CCDA',
        '#62CCDA',
        '#62CCDA',
        '#62CCDA',
        '#261e56',
        '#E5E6ED',
        '#E5E6ED',
        '#E5E6ED',
      ],
      borderColor: [
        '#62CCDA',
        '#62CCDA',
        '#62CCDA',
        '#62CCDA',
        '#62CCDA',
        '#62CCDA',
        '#62CCDA',
        '#62CCDA',
        '#261e56',
        '#E5E6ED',
        '#E5E6ED',
        '#E5E6ED',
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const VerticalBar = () => (
  <PayMentScheduleContainer>

      <PayMentScheduleWrapper>

        <PayMentScheduleFlexOne> 
            <PayMentScheduleTitlesWrapper>
                <PayMentScheduleTitle>გადახდის გრაფიკი</PayMentScheduleTitle>
                <PayMentScheduleInfoButtoWrapper>
                    <PayMentScheduleInfoButto> <PayMentScheduleInfoButtoSpan style={{ backgroundColor: "#62CCDA" }} />გადახდილი თანხა</PayMentScheduleInfoButto>
                    <PayMentScheduleInfoButto> <PayMentScheduleInfoButtoSpan style={{ backgroundColor: "#261e56" }}/>მიმდინარე დავალიანება</PayMentScheduleInfoButto>
                    <PayMentScheduleInfoButto> <PayMentScheduleInfoButtoSpan style={{ backgroundColor: "#E5E6ED" }}/>გადასახდელი თანხა</PayMentScheduleInfoButto>
                </PayMentScheduleInfoButtoWrapper>
            </PayMentScheduleTitlesWrapper>

            <PayMentScheduleChart>
                <Bar data={data} options={options} />
            </PayMentScheduleChart>
        </PayMentScheduleFlexOne>

        <PayMentScheduleFlexTwo>
           <PayMentScheduleFlexTwoSelect name="" id="">
                <option selected value="pay-ment-option-one">SQ 432 WB</option>
                <option value="pay-ment-option-two">HR 102 SW</option>
           </PayMentScheduleFlexTwoSelect>
           <PolicyCostPayMentDiv>
             <PolicyCostPayMent>პოლისის ღირებულება</PolicyCostPayMent>
             <PolicyCostPayMentAnswer>1 200 ₾</PolicyCostPayMentAnswer>
           </PolicyCostPayMentDiv>

           <TotalPayablePayMentDiv>
             <TotalPayablePayMent>სულ გადახდილი თანხა</TotalPayablePayMent>
             <TotalPayablePayMentAnswer>800 ₾</TotalPayablePayMentAnswer>
           </TotalPayablePayMentDiv>
           
           <TotalPayablePayMentDiv>
             <TotalPayablePayMent>გადასახდელი დარჩა</TotalPayablePayMent>
             <TotalPayablePayMentAnswer style={{ color: "#AAAABE" }}>400 ₾</TotalPayablePayMentAnswer>
           </TotalPayablePayMentDiv>

           <TotalPayablePayMentDiv>
             <TotalPayablePayMent>შემდეგი გადახდა - 9 აგვ 2021</TotalPayablePayMent>
             <TotalPayablePayMentAnswer style={{ color: "#AAAABE" }} >100 ₾</TotalPayablePayMentAnswer>
           </TotalPayablePayMentDiv>
         <GrayLinePayMent />
           <TotalPayablePayMentDiv>
             <TotalPayablePayMent>შემდეგი გადახდა - 9 აგვ 2021</TotalPayablePayMent>
             <TotalPayablePayMentAnswer style={{ color: "#261e56" }} >100 ₾</TotalPayablePayMentAnswer>
           </TotalPayablePayMentDiv>
           

        </PayMentScheduleFlexTwo>

      </PayMentScheduleWrapper>

  </PayMentScheduleContainer>
);

export default VerticalBar;
