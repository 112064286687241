import * as React from "react";

function SvgNotification(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={19} height={22} {...props}>
      <path
        data-name="Path 9415"
        d="M9.501 22a2.558 2.558 0 002.5-2.595H6.996A2.55 2.55 0 009.501 22zm7.515-5.784v-5.189c0-3.983-2.054-7.317-5.636-8.2v-.881a1.88 1.88 0 10-3.757 0v.882c-3.594.882-5.636 4.2-5.636 8.2v5.189L.371 17.89a1.3 1.3 0 00.877 2.218h16.494a1.308 1.308 0 00.889-2.218z"
        fill="currentColor"
      />
      <path
        data-name="Path 9512"
        d="M5.549 8.655a3.225 3.225 0 011.594-2.456"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.4}
      />
    </svg>
  );
}

export default SvgNotification;
