import React from 'react';
import Colors from '../styles/Colors';
import styled from 'styled-components';
import PersonalManagerImage from '../images/personalManage_beta-img.png';
//import { raw } from '@storybook/react';

// icons
import PersonalManagerPhoneIcon from '../icons/phone-icon.svg';
import PersonalManagerMailIcon from '../icons/mail-icon.svg';
//i`mport font from './font';
import Fonts from '../styles/Fonts';
import Responsive from 'config/Responsive';
import { Policy } from '../domain';
import { hasPersonalManager } from 'domain/types';



const PersonalManagerBox = styled.div`
    height: 300px;
    /* max-width: 350px; */
    /* min-width: 300px; */
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
    margin-bottom: 20px;
        &:hover{
            transition: 100ms all;
            cursor: pointer;
        };
    ${Responsive.laptop}{
        /* max-width: 270px;
        min-width: unset; */
        margin-bottom: 10px;
        height: 200px;
        border-radius: 6px;
    }
    ${Responsive.tablet}{
        /* max-width: 270px;
        min-width: unset; */
        margin-bottom: 10px;
        height: 200px;
        border-radius: 6px;
    }
    ${Responsive.mobile}{
        height: 200px;
        /* max-width: unset; */
        margin-top: 10px;
    }
`;
const PersonalManagerBottom = styled.div`
    align-self: stretch; 
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const PersonalManagerImageBox = styled.div`
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 50%;
    background-color: #e9e9e9;
        ${Responsive.laptop}{
            width: 55px;
            height: 55px;
        }
        ${Responsive.tablet}{
            width: 55px;
            height: 55px;
        }
        ${Responsive.mobile}{
            width: 55px;
            height: 55px;
        }
`;
const PersonalManagerName = styled.h1`
    font-size: 18px;
    color: ${Colors.TitleBlue};
    font-family: ${Fonts.FiraGOMtavruliBold}; 
    margin: unset;
    line-height: 22px;
        ${Responsive.laptop}{
            font-size: 14px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
            line-height: 20px;
            margin-right: 8px;
        }
`;
const PersonalManagerlastName = styled.h1`
    font-size: 18px;
    color: ${Colors.TitleBlue};
    font-family: ${Fonts.FiraGOMtavruliBold}; 
    margin: unset;
        ${Responsive.laptop}{
            font-size: 14px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
            line-height: 20px;
        }
`;
const PersonalManagerOtherText = styled.h1`
    font-size: 14px;
    color: ${Colors.Gray};
    font-family: ${Fonts.FiraGORegular};
    margin: unset;
    margin-top: 14px;
    font-weight: 400;
        ${Responsive.laptop}{
            font-size: 12px;
            margin-top: 5px;
        }
        ${Responsive.tablet}{
            font-size: 12px;
            margin-top: 5px;
        }
        ${Responsive.mobile}{
            font-size: 12px;
            margin-top: 5px;
        }
`;
const PersonalManagerFlex = styled.div`
    display: flex;
        ${Responsive.laptop}{
            margin-top: 10px;
        }
        ${Responsive.tablet}{
            margin-top: 10px;
        }
        ${Responsive.mobile}{
            margin-top: 20px;
        }
`;
const PersonalManagerTextBox = styled.div`
    margin-left: 30px;
        ${Responsive.laptop}{
            margin-left: 20px;
        }
        ${Responsive.tablet}{
            margin-left: 5px;
        }
        ${Responsive.mobile}{
            margin-left: 20px;
            margin-top: 10px;
        }
`;
const PersonalManagerPhone = styled.h1`
    font-size: 14px;
    color: ${Colors.Gray};
    margin: unset;
    font-weight: 400;
    font-family: ${Fonts.FiraGORegular};
        ${Responsive.laptop}{
            font-size: 14px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
        }
`;
const PersonalManagerMail = styled.h1`
    font-size: 14px;
    color: ${Colors.Gray};
    margin: unset;
    font-weight: 400;
    font-family: ${Fonts.FiraGORegular};
        ${Responsive.laptop}{
            font-size: 14px;
        }
        ${Responsive.tablet}{
            font-size: 14px;
        }
        ${Responsive.mobile}{
            font-size: 14px;
        }
`;
const PersonalManagerPhoneBox = styled.div`
    display: flex;
    align-items: center;
`;
const PersonalManagerMailBox = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: center;
        ${Responsive.laptop}{
            margin-top: 15px;
        }
        ${Responsive.tablet}{
            margin-top: 15px;
        }
        ${Responsive.mobile}{
            margin-top: 15px;
        }
`;
const PMLine = styled.div`
    height: 1px;
    width: 80%;
    background-color: #dedede;
    margin-top: 30px;
    margin-bottom: 40px;
        ${Responsive.laptop}{
            margin-top: 20px;
            margin-bottom: 20px;
        }
        ${Responsive.tablet}{
            margin-top: 20px;
            margin-bottom: 20px;
        }
        ${Responsive.mobile}{
            margin-top: 20px;
            margin-bottom: 20px;
        }
`;
const PersonalManagerPhoneImgBox = styled.img`
    margin-right: 24px;
        ${Responsive.laptop}{
            margin-right: 18px;
        }
        ${Responsive.tablet}{
            margin-right: 15px;
        }
`;
const PersonalManagerEmailImgBox = styled.img`
    margin-right: 24px;
    margin-top: 1px;
        ${Responsive.laptop}{
            margin-right: 18px;
        }
        ${Responsive.tablet}{
            margin-right: 15px;
        }
`;
const PersonalManagerBr = styled.br`
    ${Responsive.mobile}{
        display: none;
    }
`;

export interface PersonalManagerProps {
  policy: Policy;
}
 
const PersonalManager: React.FC<PersonalManagerProps> = ({ policy }: PersonalManagerProps) => {
  if(!hasPersonalManager(policy)){
    return <>
      </>;
  }
    return (  
        <PersonalManagerBox>
            <PersonalManagerFlex>

{policy.managerPhotoId && (
                <PersonalManagerImageBox>
            <img src={policy.managerPhotoId || PersonalManagerImage} alt="PersonalManagerImage" style={{ height: 100 + '%', width: 100 + '%', objectFit: 'cover', borderRadius: 50 + '%', }} />
                </PersonalManagerImageBox>
                )}

                <PersonalManagerTextBox>
        <PersonalManagerName>{policy.managerFirstName}</PersonalManagerName>
                    <PersonalManagerBr/>
        <PersonalManagerlastName>{policy.managerLastName}</PersonalManagerlastName>
                    <br/>
                    <PersonalManagerOtherText>პირადი მენეჯერი</PersonalManagerOtherText>
                </PersonalManagerTextBox>

            </PersonalManagerFlex>
                <PMLine />
            <PersonalManagerBottom>
              {policy.managerPhone && (
              <PersonalManagerPhoneBox>
                    <PersonalManagerPhoneImgBox src={PersonalManagerPhoneIcon} alt="PersonalManagerPhoneIcon"  />
        <PersonalManagerPhone>{policy.managerPhone}</PersonalManagerPhone>
              </PersonalManagerPhoneBox>
              )}
              {policy.managerEmail && (
                <PersonalManagerMailBox>
                    <PersonalManagerEmailImgBox src={PersonalManagerMailIcon} alt="PersonalManagerMailIcon"  />
        <PersonalManagerMail>{policy.managerEmail}</PersonalManagerMail>
                </PersonalManagerMailBox>
                )}
        </PersonalManagerBottom>

        </PersonalManagerBox>
    );
}
 
export default PersonalManager;
