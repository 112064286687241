import * as React from "react";

function AgroIcon(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 57 48"
      {...props}
    >
      <g data-name="Group 2784">
        <g data-name="Group 2186">
          <path
            data-name="Path 50"
            d="M32.806 0h-19.41a22.6 22.6 0 00-2.532.139c-6.092.7-10.865 6.28-10.865 12.7v22.9a12.142 12.142 0 008.536 11.537 12.376 12.376 0 003.825.718H57l-7.344-22.809-4.918-15.276a15.44 15.44 0 00-2.045-4.42A12.413 12.413 0 0034.462.11 10.9 10.9 0 0032.806 0z"
            fill="#ebe8fc"
          />
        </g>
        <g data-name="Group 2133" fill="#261e56">
          <path
            data-name="Path 8575"
            d="M46.303 33.915v-6.967a7.728 7.728 0 005.3-2.593c2.923-2.848 2.694-7.516 2.683-7.714a1.153 1.153 0 00-1.1-1.073c-.2-.011-5-.234-7.918 2.614a7.4 7.4 0 00-.83.963 10.583 10.583 0 00-2.737-5.015c-3.489-3.401-9.234-3.13-9.476-3.12a1.153 1.153 0 00-1.1 1.073c-.013.237-.288 5.834 3.2 9.235a10.5 10.5 0 007.116 3.111v9.487a10.732 10.732 0 011.167-.064c.391 0 3.307.022 3.694.064z"
          />
          <path
            data-name="Path 8576"
            d="M43.613 28.388c-7.493 0-12.5 5-12.5 7.211v1.6a.889.889 0 00.958.8h23.085a.889.889 0 00.958-.8v-1.6c0-1.294-1.719-3.548-4.8-5.273a15.894 15.894 0 00-7.701-1.938z"
          />
        </g>
        <path
          data-name="Intersection 67"
          d="M41.438 24.429a10.494 10.494 0 01-7.112-3.111c-3.49-3.4-3.216-9-3.2-9.235a1.152 1.152 0 011.099-1.073c.242-.01 5.988-.281 9.475 3.12a10.582 10.582 0 012.736 5.015 7.378 7.378 0 01.83-.963c2.372-2.312 5.977-2.6 7.356-2.62a17.732 17.732 0 01-11.184 10.519z"
          fill="#ff677f"
        />
      </g>
    </svg>
  );
}

export default AgroIcon;

