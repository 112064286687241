
// re-exported members
import getApiClient from './axios';
import requests from './requests';
import setAuthToken from '../utils/setAuthToken';
//
export const devMode = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
//                                                          MUST be www. otherwise won't open onn computers in Alpha's office
export const serverUrl = devMode ? "http://localhost:8000" : "https://www.alpha.ge";
export const apiUrl = `${serverUrl}/api`;
export const uploadsUrl = `${serverUrl}/storage`;
export const viewUploadedFileUrl = `${serverUrl}/api/storage/view?filename=`;
//
export { getApiClient };
export { requests };
export { setAuthToken };
//
export default {
    devMode,
    serverUrl,
    apiUrl,
    uploadsUrl,
};
