export default {
  auth: {
    checkPersonalNumber: "/auth/checkPersonalNumber",
    enterRegisterSmsCode: "/auth/enterRegisterSmsCode",
    registerUser: "/auth/register",
    loginUser: "/auth/login",
    userData: "/auth/user",
    logoutUser: "/auth/logout",
    resetPasswordCheckPersonalNumber: "/auth/reset/checkPersonalNumber",
    resetPasswordEnterSmsCode: "/auth/reset/enterSmsCode",
    resetPasswordProvideNewPassword: "/auth/reset/provideNewPassword",
    updateProfile: "/user/update-profile",
    sendUpdatePasswordSMS: "/user/send-update-password-sms",
    checkUpdatePasswordSMS: "/user/check-update-password-sms",
  },
  insurance: {
    policies: "/user/policies",
    policyLimits: "/user/policy-limits",
    doctors: "/doctor/list",
    providers: "/provider/list",
    visits: "/visit/list",
    visitsHistory: "/visit/history",
    claims: "/claim/list", // comes from laravel
    claimHistory: "/claim/history", // comes from LITE insurance
    addClaim: "/claim/add",
    compensations: "/compensation/list", // comes from laravel
    compensationHistory: "/compensation/history", // comes from LITE insurance
    addCompensation: "/compensation/add",
    visit: {
      getDoctorFreeDays: "/visit/doctor-free-days",
      getDoctorFreeHours: "/visit/doctor-free-hours",
      addVisit: "/visit/add-visit",
      cancelVisit: "/visit/cancel-visit",
    },
  },
  uploadFile: "/storage/upload",
};
