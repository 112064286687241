import * as React from "react";

function Filter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 14 10"
      {...props}
    >
      <path
        data-name="Filter"
        d="M6.012 10h1.977a.9.9 0 100-1.8H6.012a.9.9 0 100 1.8zM0 .9a.95.95 0 00.988.9h12.024a.9.9 0 100-1.8H.988A.95.95 0 000 .9zm3.322 5h7.356a.9.9 0 100-1.8H3.322a.9.9 0 100 1.8z"
        fill="#261e56"
      />
    </svg>
  );
}

export default Filter;

