import * as React from "react";

function GarbageIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      {...props}
    >
      <g data-name="Group 4495">
        <path
          data-name="Path 8260"
          d="M1.286 15.722A2.445 2.445 0 003.857 18h10.286a2.445 2.445 0 002.571-2.278V3.306c0-1.253-.157-1.253-1.571-1.253H2.857c-1.414 0-1.571 0-1.571 1.253zM16.714 1.139H13.5L12.587.33a1.386 1.386 0 00-.9-.33H6.313a1.386 1.386 0 00-.9.33l-.913.809H1.286A1.223 1.223 0 000 2.278a1.223 1.223 0 001.286 1.139h15.428A1.223 1.223 0 0018 2.278a1.223 1.223 0 00-1.286-1.139z"
          fill="currentColor"
        />
        <path
          data-name="Path 12815"
          d="M4.187 10.496v2.729"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.4}
        />
      </g>
    </svg>
  );
}

export default GarbageIcon;