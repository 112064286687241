import React from 'react';


function NewReferences() {
  return (
    <section>
      <div className="">
        <h3 className="">ახალი განაცხადი</h3>
        <h5 className="" style={{ width: 480 }}>E-საგარანტიო მიმართვის მოთხოვნისთვის გჭირდება შემდეგი დოკუმენტები: </h5>

        <div className='mt-4'>
          <div className='d-flex '>
            <div>
              <img className='' style={{ height: 20 }} src='https://www.shareicon.net/data/2016/02/11/289550_button_192x192.png'alt="piradoba" />
            </div>
            <div>
              <h6 className='ml-3'>პირადობის დამადასტურებელი საბუთი</h6>
            </div>
          </div>

          <div className='mt-2 d-flex'>
            <div>
              <img className='' style={{ height: 20 }} src='https://www.shareicon.net/data/2016/02/11/289550_button_192x192.png' alt="button"/>
            </div>
            <div style={{ width: 450 }}>
              <h6 className='ml-3' >ჩასატარებელ სამედიცინო მომსახურებასთან  დაკავშირებული სამედიცინო და ფინანსური დოკუმენტაცია</h6>
            </div>
          </div>
        </div>
        <h3 className="mt-5">ნიკა ჟღენტი</h3>
      </div>

      <div className="selectForm">
        <select>
          <option style={{ display: "none" }}>  აირჩიე მიმართვის ტიპი</option>s
          <option value="Appeal">მიმართვის ტიპი N1</option>
          <option value="Appeal-2">მიმართვის ტიპი N2</option>
          <option value="Appeal-3">მიმართვის ტიპი N3</option>
          <option value="Appeal-4">მიმართვის ტიპი N4</option>
          <option value="Appeal-5">მიმართვის ტიპი N5</option>
        </select>
      </div>

    </section>

  );
}


export default NewReferences;
