import React from 'react';
import { Global, css } from '@emotion/core';

const GlobalStyles = (props: any) => (
  <Global
    {...props}
    styles={css`
      html {
        box-sizing: border-box;
        font-size: 62.5%;
      }

      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }

      * {
        margin: 0;
        padding: 0;
      }

      body {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.25;
        color: #000;
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      /* width */
      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
        ::-webkit-scrollbar-track {
          background: #fff;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #261e56;
      }
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #261e56;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input[type=number] {
        -moz-appearance: textfield;
      }
    `}
  />
);

export default GlobalStyles;
