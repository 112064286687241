import { AddCompensationUploadType } from './types';

export type CompensationTypeKeyword = "ambulatory" | "meds" | "dental";
export type CompensationType = {
  name: string;
  keyword: CompensationTypeKeyword;
};
export const compensationTypes: Record<
  CompensationTypeKeyword,
  CompensationType
> = {
  ambulatory: { keyword: "ambulatory", name: "ამბულატორია/სტაციონარი" },
  meds: { keyword: "meds", name: "მედიკამენტები" },
  dental: { keyword: "dental", name: "სტომატოლოგიური" },
};
export const getCompensationTypeName = (typeName: string) => {
  if(typeName in compensationTypes){
    return compensationTypes[typeName as CompensationTypeKeyword].name;
  } else {
    return 'ანაზღაურება';
  }
};
export default CompensationType;

export const compensationFileTypeTitles: Record<AddCompensationUploadType, string> = {
  // shared file types between compensation types
  // files specific to ambulatory type
  ambulatoryForm100: 'ბეჭდითა და ხელმოწერით დამოწმებული შესაბამის სატიტულო ფურცელზე გამოწერილი ექიმის დანიშნულება ( რეცეპტი) ან დაწესებულების ბეჭდით, შტამპითა და ხელმოწერით დამოწმებული ფ №IV-100ა',
  ambulatoryCheck: 'საფასურის გადახდის დამადასტურებელი სალაროს ჩეკი',
  ambulatoryIncomeOrder: 'დაწესებულების მიერ გაცემული შემოსავლის ორდერი, რომელზეც დატანილია ჩატარებული მომსახურების ჩამონათვალი',
  ambulatoryCalculation: 'კალკულაცია (სავალდებულოა სტაციონარული მომსახურებეისას, ამბულატორიულიული მომსახურებისას მხოლოდ მანიპულაციის ან ამბულატორიული ოპერაციის შემთხვევაში)',
  ambulatoryInvoice: 'ანგარიშფაქტურა ( სავალდებულოა სტაციონარული მომსახურებეისას, ამბულატორიულიული მომსახურებისას მხოლოდ მანიპულაციის ან ამბულატორიული ოპერაციის შემთხვევაში)',
  ambulatoryExamResults: 'ჩატარებული გამოკვლევების დასკვნები',
  // files specific to meds type
  medsForm100: 'ბეჭდითა და ხელმოწერით დამოწმებული შესაბამის სატიტულო ფურცელზე გამოწერილი ექიმის დანიშნულება ( რეცეპტი) ან დაწესებულების ბეჭდით, შტამპითა და ხელმოწერით დამოწმებული ფ №IV-100ა',
  medsCheck: 'საფასურის გადახდის დამადასტურებელი სალაროს ჩეკი',
  medsIncomeOrder: 'აფთიაქის მიერ გაცემული შემოსავლის ორდერი, რომელზეც დატანილია შესაბამისი ფარმაცევტული პროდუქტის დასახელება',
  // files specific to dental type
  dentalForm100: 'ბეჭდითა და ხელმოწერით დამოწმებული შესაბამის სატიტულო ფურცელზე გამოწერილი ექიმის დანიშნულება ( რეცეპტი) ან დაწესებულების ბეჭდით, შტამპითა და ხელმოწერით დამოწმებული ფ №IV-100ა',
  dentalCheck: 'საფასურის გადახდის დამადასტურებელი სალაროს ჩეკი',
  dentalCalculation: 'გაწეული სამედიცინო მომსახურების დეტალური კალკულაცია',
  dentalIncomeOrder: 'სალაროს შემოსავლის ორდერი ან მასთან გათანაბრებული ბეჭდით დამოწმებული დოკუმენტი',
  dentalExamResults: 'ჩატარებული გამოკვლევების დასკვნები (თუ ჩატარდა რაიმე სპეციფიური გამოკვლევა: მაგ კომპიუტერული ტომოგრაფია)',
  dentalXrayPhotos: 'რენტგენოლოგიური კვლევის სურათი მკურნალობამდე და მკურნალობის შემდეგ (დასკანერებული, ან ფოტო, ან დისკიდან ატვირთული ინფორმაცია)',
};
