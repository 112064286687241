import * as React from "react";

function PhoneIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16.002"
      {...props}
    >
      <path
        data-name="Path 9570"
        d="M15.275 10.5l-1.013-1.009a2.4 2.4 0 00-3.394 0l-.255.255a1.623 1.623 0 01-2.154.125 14.3 14.3 0 01-1.233-1.1 14.272 14.272 0 01-1.1-1.233 1.623 1.623 0 01.125-2.154l.255-.255a2.406 2.406 0 000-3.394L5.495.724A2.257 2.257 0 003.068.132a4.457 4.457 0 00-1.909 1.384 4.028 4.028 0 00-.926 1.7A6.759 6.759 0 00.569 7.63a13.5 13.5 0 003.117 4.683 13.5 13.5 0 004.683 3.117 8.1 8.1 0 002.839.57 4.407 4.407 0 003.275-1.159 4.456 4.456 0 001.385-1.909 2.257 2.257 0 00-.593-2.432zm0 0"
        fill="currentColor"
      />
      <path
        data-name="Path 9567"
        d="M10.459 13.731a2.066 2.066 0 002.156-.338"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
      />
    </svg>
  );
}

export default PhoneIcon;
