import * as React from "react";

function Warranty(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 20"
      {...props}
    >
      <path
        data-name="Path 9457"
        d="M15.69 0H2.31A2.231 2.231 0 000 2.143v15.714A2.231 2.231 0 002.31 20h13.38A2.231 2.231 0 0018 17.857V2.143A2.235 2.235 0 0015.69 0z"
        fill="currentColor"
      />
      <path
        data-name="Path 9694"
        d="M9.684 7.3l1.463 1.337 2.737-2.4"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.4}
      />
    </svg>
  );
}

export default Warranty;
