import * as React from "react";

function Property(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 60 52"
      {...props}
    >
      <g data-name="Group 4867">
        <path
          data-name="Intersection 100"
          d="M10 52A10 10 0 010 42V10A10 10 0 0110 0h50l-7.845 24.71L46.9 41.26a16.838 16.838 0 01-2.184 4.788 13.246 13.246 0 01-8.793 5.828 11.505 11.505 0 01-1.768.124z"
          fill="#ebe8fc"
        />
        <g data-name="Group 4609">
          <g data-name="Group 4604">
            <path
              d="M57.528 23.952L44.634 12.245a.953.953 0 00-1.272 0l-12.908 11.72A1.443 1.443 0 0030 25.003a1.5 1.5 0 001.532 1.458h.368v11.787a1.8 1.8 0 001.839 1.75h5.9c.507 0 1.148-.392 1.148-.875v-5.992a.3.3 0 01.306-.292h5.886a.3.3 0 01.306.292v5.991a.9.9 0 00.919.875h6.058a1.8 1.8 0 001.839-1.75V26.462h.364A1.5 1.5 0 0058 25.003a1.455 1.455 0 00-.472-1.051zm0 0"
              fill="#261e56"
            />
            <path
              data-name="Intersection 59"
              d="M31.9 26.461h-.367A1.5 1.5 0 0130 25.002a1.444 1.444 0 01.454-1.038l12.908-11.719a.953.953 0 011.272 0l4.925 4.472A23.82 23.82 0 0131.9 30.415z"
              fill="#ff677f"
            />
          </g>
          <path
            data-name="Path 12877"
            d="M53.089 32.709v-2.6"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.6}
          />
        </g>
      </g>
    </svg>
  );
}

export default Property;
