import * as React from "react";

function OldWatchIcon(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      data-name="Group 3453"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 9 13"
      {...props}
    >
      <path
        d="M8.538 11.762v-1.374a3.012 3.012 0 00-.831-2.3l-.967-.9a.949.949 0 010-1.38l.967-.9a3.013 3.013 0 00.831-2.3v-1.37C8.57 1.238 9 .961 9 .619A.61.61 0 008.4 0H.6a.61.61 0 00-.6.619.854.854 0 00.449.619v1.374a3.022 3.022 0 00.844 2.3l.967.9a.949.949 0 010 1.38l-.967.9a3.023 3.023 0 00-.844 2.3v1.374a.854.854 0 00-.449.615.61.61 0 00.6.619h7.8a.589.589 0 00.6-.619c0-.375-.462-.619-.462-.619z"
        fill="#767880"
      />
      <path
        data-name="Path 9565"
        d="M7.04 2.363a2.424 2.424 0 01-.936 1.606"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.2}
      />
    </svg>
  );
}

export default OldWatchIcon;
