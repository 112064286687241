import * as React from "react";

function LayoutCreditCard(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 21 16"
      {...props}
    >
      <g data-name="credit card" transform="translate(-100 -625)">
        <rect
          data-name="Rectangle 1603"
          width={21}
          height={16}
          rx={2}
          transform="translate(100 625)"
          fill="currentColor"
        />
        <path
          data-name="Path 9703"
          d="M103.891 636.5h3.885"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={1.5}
        />
      </g>
    </svg>
  );
}

export default LayoutCreditCard;

