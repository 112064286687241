import styled from "styled-components";
import Colors from 'styles/Colors';
import CloseIcon from "../icons/CloseIcon";
import NotAccepted from "icons/NotAcceptet";
import PopUpAcceptedIcon from "icons/Accepted";
import Responsive from "../config/Responsive";
import Fonts from "styles/Fonts";

const PopupWrapper = styled.div`
    width: 100%;
    height: 100%;
    color: white;
    position: fixed;
    z-index: 10;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
        ${Responsive.mobile} {
                align-items: flex-end;
            }
`;
const PopUpInsideBox = styled.div`
    background-color: white;
    height: 350px;
    width: 550px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${Colors.TitleBlue};
    position: relative;
        ${Responsive.mobile} {
            width: 100%;
            height: 245px;
            border-radius: 18px;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
        }
`;
const PopUpCloseIcon = styled(CloseIcon)`
    color: ${Colors.Gray};
    width: 25px;
    height: 25px;
    transition: all 200ms;
`;
const PopUpCloseBtn = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: 0px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
        &:focus {
            outline: none;
        }
        &:hover {
            ${PopUpCloseIcon} {
                color: ${Colors.HoverRed};
                transition: all 200ms;
                transform: rotate(90deg);
            }
        }
`;
const PopUpTitleMessage = styled.h1`
    font-size: 18px;
    color: ${Colors.TitleBlue};
    margin: 0px;
    margin-top: 20px;
    text-align: center;
    font-family: ${Fonts.FiraGOMtavruliBold};
        ${Responsive.mobile}{
            font-size: 14px;
            margin-top: 40px;
        }
`;
const NotAcceptedIcon = styled(NotAccepted)`
    height: 90px;
    width: 90px;
    user-select: none;
        ${Responsive.mobile}{
            width: 60px;
            height: 60px;
        }
`;
const AcceptedIcon = styled(PopUpAcceptedIcon)`
    height: 90px;
    width: 90px;
    user-select: none;
        ${Responsive.mobile}{
            width: 60px;
            height: 60px;
        }
`;


type PopUpProps = {
    popUpMessage: string;
    popUpVisible: boolean;
    popUpClose: () => void;
}

function PopUp(props: PopUpProps ) {
    return (
        <PopupWrapper style={{ display: (props.popUpVisible ? 'flex' : 'none') }}> 
            <PopUpInsideBox>
                <PopUpCloseBtn onClick={props.popUpClose}><PopUpCloseIcon /></PopUpCloseBtn>
                {props.popUpMessage === "Unauthorized" && <NotAcceptedIcon />}
                <PopUpTitleMessage>{props.popUpMessage}</PopUpTitleMessage>
            </PopUpInsideBox>
        </PopupWrapper>
    )
}

export default PopUp
