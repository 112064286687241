import React, { useState } from "react";
import styled from "styled-components";
import Fonts from "styles/Fonts";
import Colors from "styles/Colors";

// icons
import FilterIcon from "icons/Filter";
import Responsive from "config/Responsive";

import { useSelector } from "react-redux";
import { RootState } from "../slices";
import { InsuranceState } from "../domain";
import { ProviderClinic } from "../domain";

function onlyUnique(value: any, index: number, self: any) {
  return self.indexOf(value) === index;
}

const ProviderClinicsContainer = styled.div`
  margin-top: 40px;
  box-shadow: 0px 0px 5px 3px rgb(152 152 219 / 12%);
  border-radius: 10px;
  background-color: #ffffff;
  ${Responsive.laptop} {
    margin-top: 25px;
  }
  ${Responsive.tablet} {
    margin-top: 25px;
  }
  ${Responsive.mobile} {
    margin-top: 30px;
  }
`;
const ProviderClinicsWrapper = styled.div`
  padding: 50px;
  ${Responsive.laptop} {
    padding: 35px;
  }
  ${Responsive.tablet} {
    padding: 35px;
  }
  ${Responsive.mobile} {
    padding: 30px;
  }
`;

const ProviderClinicsTitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ProviderClinicsTitle = styled.h1`
  font-size: 18px;
  font-family: ${Fonts.FiraGORegular};
  margin: unset;
  color: ${Colors.TitleBlue};
  ${Responsive.laptop} {
    font-size: 14px;
  }
  ${Responsive.tablet} {
    font-size: 14px;
  }
  ${Responsive.mobile} {
    font-size: 14px;
  }
`;
const ProviderClinicsHideButton = styled.button`
  height: 50px;
  width: 50px;
  border-radius: 6px;
  background-color: #ebe8fc;
  border: unset;
  &:focus,
  &:hover {
    outline: none;
  }
  ${Responsive.laptop} {
    height: 42px;
    width: 42px;
    border-radius: 4px;
  }
  ${Responsive.tablet} {
    height: 42px;
    width: 42px;
    border-radius: 4px;
  }
  ${Responsive.mobile} {
    height: 38px;
    width: 38px;
    border-radius: 4px;
  }
`;
const ProviderClinicsHideIcon = styled(FilterIcon)`
  height: 12px;
  margin-top: 5px;
`;

const ProviderClinicsFilterWrapper = styled.div`
  height: 140px;
  width: 100%;
  margin-top: 50px;
  border-top: 1px solid #e5e6ed;
  border-bottom: 1px solid #e5e6ed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${Responsive.laptop} {
    height: 110px;
    margin-top: 35px;
  }
  ${Responsive.tablet} {
    height: 110px;
    margin-top: 35px;
  }
  ${Responsive.mobile} {
    height: 140px;
    margin-top: 30px;
    flex-wrap: wrap;
  }
`;
const ProviderClinicFilterSelect = styled.select`
  height: 50px;
  width: 23%;
  border: 1px solid #e5e6ed;
  border-radius: 6px;
  color: #aaaabe;
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  outline: none;
  padding-left: 20px;
  ${Responsive.laptop} {
    height: 42px;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 15px;
  }
  ${Responsive.tablet} {
    height: 42px;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 15px;
  }
  ${Responsive.mobile} {
    height: 38px;
    border-radius: 4px;
    font-size: 12px;
    padding-left: 15px;
    width: 48%;
  }
`;
const ProviderClinicFilterButton = styled.button`
  height: 50px;
  width: 23%;
  border: 1px solid #261e56;
  border-radius: 6px;
  font-size: 16px;
  font-family: ${Fonts.FiraGOMtavruliRegular};
  font-weight: 400;
  background-color: #fff;
  color: ${Colors.Primary};
  &:hover,
  &:focus {
    outline: none;
    background-color: ${Colors.HoverRed};
    border-color: ${Colors.HoverRed};
    color: #fff;
  }
  ${Responsive.laptop} {
    height: 42px;
    border-radius: 4px;
    font-size: 14px;
  }
  ${Responsive.tablet} {
    height: 42px;
    border-radius: 4px;
    font-size: 14px;
  }
  ${Responsive.mobile} {
    width: 48%;
    height: 38px;
    border-radius: 4px;
    font-size: 12px;
  }
`;

const Table = styled.div``;
const TableHeader = styled.div`
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  ${Responsive.laptop} {
    height: 60px;
  }
  ${Responsive.tablet} {
    height: 60px;
  }
  ${Responsive.mobile} {
    display: none;
  }
  /* background-color: red; */
`;
const TableHeaderColumn = styled.h1`
  font-size: 14px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  margin: unset;
  color: #767880;
  line-height: 90px;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 300ms;
  &:nth-child(1) {
    /* background-color: green; */
    width: 5%;
    text-align: center;
  }
  &:nth-child(2) {
    /* background-color: yellow; */
    width: 35%;
    text-align: left;
  }
  &:nth-child(3) {
    /* background-color: pink; */
    width: 25%;
    padding-left: 10px;
    text-align: left;
  }
  &:nth-child(4) {
    /* background-color: black; */
    width: 35%;
    padding-left: 10px;
    text-align: left;
  }
  &:nth-child(5) {
    /* background-color: aqua; */
    width: 20%;
    text-align: right;
    padding-left: 10px;
  }
  ${Responsive.laptop} {
    font-size: 12px;
    line-height: 60px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    line-height: 60px;
  }
  ${Responsive.mobile} {
    display: none;
  }
`;

const TableRow = styled.div`
  ${Responsive.mobile} {
    height: 80px;
    position: relative;
    border-bottom: 1px dashed #767880;
  }
`;
const TableRowColumn = styled.h1`
  font-size: 15px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  margin: unset;
  color: #767880;
  line-height: 80px;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 300ms;
  &:nth-child(1) {
    /* background-color: green; */
    width: 5%;
    text-align: center;
    ${Responsive.mobile} {
      display: none;
    }
  }
  &:nth-child(2) {
    /* background-color: yellow; */
    width: 35%;
    text-align: left;
    ${Responsive.mobile} {
      width: 70%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &:nth-child(3) {
    /* background-color: pink; */
    width: 25%;
    padding-left: 10px;
    text-align: left;
    ${Responsive.mobile} {
      width: 60%;
      position: absolute;
      top: 40px;
      left: 0;
      padding-left: unset;
    }
  }
  &:nth-child(4) {
    /* background-color: black; */
    width: 35%;
    padding-left: 10px;
    text-align: left;
    ${Responsive.mobile} {
      width: 40%;
      position: absolute;
      top: 40px;
      right: 0;
      text-align: right;
    }
  }
  &:nth-child(5) {
    /* background-color: aqua; */
    width: 20%;
    padding-left: 10px;
    text-align: right;
    ${Responsive.mobile} {
      display: none;
    }
  }
  ${Responsive.laptop} {
    font-size: 14px;
    line-height: 56px;
  }
  ${Responsive.tablet} {
    font-size: 14px;
    line-height: 56px;
  }
  ${Responsive.mobile} {
    font-size: 12px;
    line-height: 40px;
  }
`;

function ProviderClinics() {
  const [showFilters, setShowFilters] = useState(true);
  const { providers, providersLoading } = useSelector<
    RootState,
    InsuranceState
    >((data) => data.insurance);
  const [filterCity, setFilterCity] = useState<string | undefined>();
  const [filterServices, setFilterServices] = useState<string | undefined>();
  const isFiltered = filterCity || filterServices;
  return (
    <ProviderClinicsContainer>
      <ProviderClinicsWrapper>
        <ProviderClinicsTitleDiv>
          <ProviderClinicsTitle>პროვაიდერი კლინიკები</ProviderClinicsTitle>
          <ProviderClinicsHideButton
            onClick={() => setShowFilters(!showFilters)}
          >
            {" "}
            <ProviderClinicsHideIcon />{" "}
          </ProviderClinicsHideButton>
        </ProviderClinicsTitleDiv>
        {showFilters ? (
          <ProviderClinicsFilterWrapper>
            <ProviderClinicFilterSelect
              name="provider-clinic"
              id="provider-clinic-city"
              onChange={event => setFilterCity(event.target.value)}
            >
              <option selected value="">
                ქალაქი
          </option>
            {providers.map(p => p.city).filter(onlyUnique).filter(s => !!s).map(c => (
                <option value={c}>{c}</option>
            ))}
            </ProviderClinicFilterSelect>

            {/* <ProviderClinicFilterSelect
              name="provider-clinic"
              id="provider-clinic-name"
            >
              <option selected disabled hidden value="">
                კლინიკა
              </option>
              <option value="">კლინიკის დასახელება</option>
            </ProviderClinicFilterSelect> */}

            <ProviderClinicFilterSelect
              name="provider-clinic"
              id="provider-clinic-profile"
              onChange={event => setFilterServices(event.target.value)}
            >
              <option selected value="">
                პროფილი
              </option>
              {/* <option value="">სტომატოლოგია</option> */}
              {providers.map(p => p.services).filter(onlyUnique).filter(s => !!s).map(s => (
                <option value={s}>{s}</option>
              ))}
            </ProviderClinicFilterSelect>

            {/* <ProviderClinicFilterButton>გაფილტრვა</ProviderClinicFilterButton> */}
            {/* <ProviderClinicFilterButton onClick={() => clearFilters()}>გასუფთავება</ProviderClinicFilterButton> */}
          </ProviderClinicsFilterWrapper>
        ) : null}

        <Table>
          <TableHeader>
            <TableHeaderColumn>N</TableHeaderColumn>
            <TableHeaderColumn>დასახელება</TableHeaderColumn>
            <TableHeaderColumn>პროფილი</TableHeaderColumn>
      {/* <TableHeaderColumn>მდებარეობა</TableHeaderColumn> */}
            <TableHeaderColumn>მისამართი</TableHeaderColumn>
      </TableHeader>
      {/* hide providers until filter is activated */}
      {isFiltered && providers.filter(p => {
        if(filterCity && p.city != filterCity){
          return false;
        }
        if(filterServices && p.services != filterServices){
          return false;
        }
        return true;
      }).map((p: ProviderClinic, i: number) => (
          <TableRow>
          <TableRowColumn>{i+1}</TableRowColumn>
          <TableRowColumn>{p.name}</TableRowColumn>
          <TableRowColumn>{p.services}</TableRowColumn>
          {/* <TableRowColumn>{p.city}</TableRowColumn> */}
          <TableRowColumn>{p.address}</TableRowColumn>
          </TableRow>
      ))}
        </Table>
      </ProviderClinicsWrapper>
    </ProviderClinicsContainer>
  );
}

export default ProviderClinics;
