import React from 'react';

function CompensationList(){
    return (
      <div>
        ავტოდაზღვევის შეძენა
      </div>
    );
}

export default CompensationList;