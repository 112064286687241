import React from "react";
import styled from "styled-components";

//import FormStyles from '../styles/Form';

const RightSideBarCustomStyle = styled.div``;

const ListVertical: React.FC<{ children: any; margin?: number }> = ({
  children,
}) => {
  return (
    <>
      {children &&
        children.filter((c: any) => !!c).map((child: any, index: any) => (
          <RightSideBarCustomStyle key={child.key ? child.key : index}>
            {child}
          </RightSideBarCustomStyle>
        ))}
    </>
  );
};
export default ListVertical;
