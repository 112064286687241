import * as React from "react";

function CopyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 15.2 17.2"
      {...props}
    >
      <g
        data-name="Group 3267"
        transform="translate(-1200.8 -241)"
        opacity={0.6}
        fill="currentColor"
      >
        <rect
          data-name="Rectangle 1327"
          width={10}
          height={11}
          rx={2}
          transform="translate(1206 241)"
        />
        <g
          data-name="Rectangle 1328"
          transform="translate(1202 246)"
          stroke="#fff"
          strokeWidth={1.2}
        >
          <rect width={10} height={11} rx={2} stroke="none" />
          <rect
            x={-0.6}
            y={-0.6}
            width={11.2}
            height={12.2}
            rx={2.6}
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
}

export default CopyIcon;

