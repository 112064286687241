import * as React from "react";

function BankGuarantyIcon(props) {
  const width = props.size || props.width || 19;
  const height = width * 22 / 19;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 60 52"
      {...props}
    >
      <g data-name="Group 4866">
        <path
          data-name="Intersection 99"
          d="M10 52A10 10 0 010 42V10A10 10 0 0110 0h50l-7.845 24.71L46.9 41.26a16.838 16.838 0 01-2.184 4.788 13.246 13.246 0 01-8.793 5.828 11.505 11.505 0 01-1.768.124z"
          fill="#ebe8fc"
        />
        <g data-name="Group 4610">
          <g data-name="Group 4529">
            <path
              d="M45.552 12.003a16.627 16.627 0 00-6.172 1.422l-2.214-.95c-1.755-1.062-3.49-.168-2.945 1.963l.531 2.887a12.5 12.5 0 00-1.912 3.042 2.668 2.668 0 01-2.424 1.639h-.238a1.448 1.448 0 00-1.412 1.481v4.16a1.448 1.448 0 001.412 1.478h.832a2.627 2.627 0 012.3 1.378 13.372 13.372 0 003.627 4.163v3.769a1.524 1.524 0 001.486 1.559h2.456c.673 0 1.048-.452 1.436-1.157l.871-2.538a8.381 8.381 0 006.377.082l.851 2.456c.42.763.763 1.157 1.436 1.157h1.886a1.524 1.524 0 001.486-1.559v-3.564a11.694 11.694 0 004.676-9.91c0-5.253-3.263-9.776-8.467-11.886a15.865 15.865 0 00-5.879-1.072z"
              fill="#261e56"
            />
            <path
              data-name="Intersection 80"
              d="M33.308 30.502a2.627 2.627 0 00-2.3-1.378h-.831a1.448 1.448 0 01-1.411-1.477v-4.16a1.448 1.448 0 011.411-1.485h.239a2.668 2.668 0 002.423-1.64 12.524 12.524 0 011.912-3.038l-.531-2.886c-.544-2.136 1.19-3.025 2.946-1.963l2.215.947a16.625 16.625 0 016.171-1.42 15.871 15.871 0 015.879 1.073l.07.028c0 10.142-7.28 18.5-16.646 19.618a12.751 12.751 0 01-1.547-2.219z"
              fill="#ff677f"
            />
            <path
              data-name="Path 9475"
              d="M55.596 19.778a4.102 4.102 0 00-1.894-1.955"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.6008320000000003}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BankGuarantyIcon;
