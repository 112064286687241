import * as React from "react";

function NavBarBorder(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 83 70"
      {...props}
    >
      <path
        d="M32.314 70.001H83v-70H0L16.395 56.81a20.515 20.515 0 002.729 5.88 16.565 16.565 0 0010.982 7.157 14.589 14.589 0 002.208.154z"
        fill="#261e56"
      />
    </svg>
  );
}

export default NavBarBorder;
