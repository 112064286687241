import React from "react";
import { useState, useEffect } from "react";

// tab imports
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

// for styles
import Colors from "styles/Colors";
import Fonts from "styles/Fonts";
import styled from "styled-components";
import ButtonFilter from "../../icons/ButtonFilter";
import Responsive from "config/Responsive";

// for decore icons or image
import PlusIcon from "../../icons/PlusIcon";

import DateInput from "../../components/Input/DateInput";

// header css for custom tabs
import "../../components/header.css";
import { Link } from "react-router-dom";

import {
  getVisits,
  getVisitsHistory,
  getDoctors,
  getProviders,
  cancelVisit,
} from "../../slices/insuranceSlice";

import { useSelector } from "react-redux";
import { RootState } from "../../slices";
import {
  InsuranceState,
  Policy,
  Visit,
  Doctor,
  ProviderClinic,
} from "../../domain";
import { useAppDispatch } from "../../config/store";
import { unwrapResult } from "@reduxjs/toolkit";

import meaningfulErrorMessage from "../../utils/meaningfulErrorMessage";

import dayjs from 'dayjs';
import { uniqBy } from 'lodash';

// custom line
const GrayLineForDoctors = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${Colors.Gray};
  margin-top: 50px;
  margin-bottom: 50px;
  ${Responsive.laptop} {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  ${Responsive.tablet} {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  ${Responsive.mobile} {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;
// style
const VisitDoctorContainer = styled.div`
  min-height: 100vh;
  /* background-color: gray !important; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  ${Responsive.laptop} {
  }
  ${Responsive.tablet} {
  }
  ${Responsive.mobile} {
    min-height: unset;
    height: fit-content;
  }
`;
const DoctorTabsStyled = styled(Tabs)`
  background-color: #ffffff !important;
  height: 120px !important;
  opacity: unset !important;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  ${Responsive.laptop} {
    height: 85px !important;
    min-height: 85px !important;
  }
  ${Responsive.tablet} {
    height: 85px !important;
    min-height: 85px !important;
  }
  ${Responsive.mobile} {
    height: 75px !important;
    min-height: 75px !important;
    height: 280px !important;
    position: relative;
  }
`;
const DoctorTabStyle = styled(Tab)`
  font-size: 18px !important;
  outline: none !important;
  color: ${Colors.Primary} !important;
  max-width: 200px !important;
  width: 100% !important;
  font-family: ${Fonts.FiraGOMtavruliRegular} !important;
  font-weight: 400 !important;
  margin-top: 38px !important;
  opacity: unset !important;
  ${Responsive.laptop} {
    font-size: 14px !important;
    max-width: 140px !important;
    margin-top: unset !important;
    height: 85px !important;
  }
  ${Responsive.tablet} {
    font-size: 14px !important;
    max-width: 115px !important;
    min-width: 100px !important;
    margin-top: unset !important;
    height: 85px !important;
  }
  ${Responsive.mobile} {
    font-size: 14px !important;
    max-width: 150px !important;
    margin-top: unset !important;
    height: 75px !important;
    padding: unset !important;
    line-height: 15px !important;
    &:focus {
      width: 100% !important;
    }
  }
`;
const VisitDoctorTabDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 33px;
  margin-right: 50px;
  ${Responsive.laptop} {
    margin-top: unset;
    margin-right: 30px;
  }
  ${Responsive.tablet} {
    margin-top: unset;
    margin-right: 0px;
  }
  ${Responsive.mobile} {
    margin: unset;
    position: absolute;
    top: 130px;
    left: 0;
    justify-content: center;
    z-index: 1;
    flex-wrap: wrap;
  }
`;
const VisitDoctorTabSelect = styled.select`
  height: 50px;
  width: 30%;
  border-radius: 6px;
  border: 1px solid #e5e6ed;
  color: #636381;
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  outline: none;
  padding-left: 20px;
  ${Responsive.laptop} {
    height: 42px;
    width: 35%;
    font-size: 14px;
    border-radius: 4px;
    padding-left: 12px;
  }
  ${Responsive.tablet} {
    height: 42px;
    width: 37%;
    font-size: 14px;
    border-radius: 4px;
    padding-left: 12px;
  }
  ${Responsive.mobile} {
    height: 38px;
    width: 80%;
    font-size: 12px;
    border-radius: 4px;
    padding-left: 15px;
  }
`;
// const DoctorNewVisitLink = styled(Link)`
//   width: 30%;
// `;
const VisitDoctorTabNewButton = styled(Link)`
  height: 50px;
  width: 30%;
  border-radius: 6px;
  border: unset;
  background-color: #261e56;
  color: #fff;
  outline: none;
  font-size: 14px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus,
  &:hover {
    outline: none;
    color: #fff;
    text-decoration: unset;
    background-color: ${Colors.HoverRed};
  }
  ${Responsive.laptop} {
    height: 42px;
    width: 35%;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 15px;
    margin-right: 15px;
  }
  ${Responsive.tablet} {
    height: 42px;
    width: 37%;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 15px;
    margin-right: 15px;
  }
  ${Responsive.mobile} {
    height: 38px;
    width: 60%;
    border-radius: 4px;
    font-size: 14px;
    margin: unset;
    margin-top: 20px;
  }
`;
const NewVisitPlusIcon = styled(PlusIcon)`
  height: 10px;
  width: 10px;
  color: #fff;
  margin-right: 10px;
  margin-top: -3px;
  ${Responsive.laptop} {
    margin-top: -3px;
  }
  ${Responsive.tablet} {
    margin-top: -2px;
  }
`;
const VisitDoctorTabHideButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 6px;
  border: unset;
  margin: unset;
  background-color: #ebe8fc;
  transition: all 300ms;
  &:focus {
    outline: none;
  }
  ${Responsive.laptop} {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
  ${Responsive.tablet} {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
  ${Responsive.mobile} {
    width: 38px;
    height: 38px;
    margin-top: 20px;
    margin-left: 20px;
    border-radius: 4px;
  }
`;
const VisitsHideButtonFilterIcon = styled(ButtonFilter)`
  width: 16px;
`;
// selects style
const VisitDoctorSelectContainer = styled.div`
  transition: all 300ms;
`;
const VisitDoctorTopSelects = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
  ${Responsive.laptop} {
    margin-top: 35px;
  }
  ${Responsive.tablet} {
    margin-top: 35px;
  }
  ${Responsive.mobile} {
    margin-top: unset;
    flex-wrap: wrap;
  }
`;
const VisitDoctorTopSelectsGlobal = styled.select`
  height: 50px;
  width: 23%;
  border-radius: 6px;
  border: 1px solid #E5E6ED;
  color: #aaaabe;
  padding-left: 20px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  font-size: 16px;
  outline: none;
  &:focus,
  &:hover {
    outline: none;
  }
  ${Responsive.laptop} {
    height: 42px;
    width: 23%;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 20px;
  }
  ${Responsive.tablet} {
    height: 42px;
    width: 23%;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 20px;
  }
  ${Responsive.mobile} {
    height: 38px;
    width: 80%;
    border-radius: 4px;
    font-size: 12px;
    padding-left: 10px;
    margin-top: 10px;
  }
`;
const VisitDoctorBottomSelectsGlobal = styled.select`
  height: 50px;
  width: 18%;
  border-radius: 6px;
  border: 1px solid #E5E6ED;
  color: #aaaabe;
  padding-left: 15px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  font-size: 16px;
  outline: none;
  &:focus,
  &:hover {
    outline: none;
  }
  ${Responsive.laptop} {
    height: 42px;
    width: 18%;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 15px;
  }
  ${Responsive.tablet} {
    height: 42px;
    width: 18%;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 10px;
  }
  ${Responsive.mobile} {
    height: 38px;
    width: 80%;
    border-radius: 4px;
    font-size: 12px;
    padding-left: 10px;
    margin-top: 10px;
  }
`;
const VisitDoctorBottomSelects = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  ${Responsive.laptop} {
    margin-top: 35px;
  }
  ${Responsive.tablet} {
    margin-top: 35px;
  }
  ${Responsive.mobile} {
    margin-top: unset;
    flex-wrap: wrap;
  }
`;
const VisitDoctorFilterButton = styled.button`
  height: 50px;
  width: 18%;
  border: 1px solid ${Colors.Primary};
  border-radius: 6px;
  outline: none;
  color: ${Colors.Primary};
  background-color: #fff;

  font-family: ${Fonts.FiraGOMtavruliRegular};
  &:focus,
  &:hover {
    outline: none;
    color: #fff;
    background-color: ${Colors.Primary};
    border-color: ${Colors.Primary};
  }
  ${Responsive.laptop} {
    height: 42px;
    width: 18%;
    border-radius: 4px;
    font-size: 16px;
  }
  ${Responsive.tablet} {
    height: 42px;
    width: 18%;
    border-radius: 4px;
    font-size: 14px;
  }
  ${Responsive.mobile} {
    height: 38px;
    width: 98%;
    border-radius: 4px;
    font-size: 12px;
    margin-top: 15px;
  }
`;
// Tab One styles
const TabContainer = styled.div``;
const TableHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  user-select: none;
  ${Responsive.mobile} {
    display: none;
  }
`;
const TableHeaderColumn = styled.h1`
  font-size: 14px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  color: #ced0dd;
  margin: unset;
  display: block;
  line-height: 50px;
  &:nth-child(1) {
    width: 5%;
    text-align: center;
    /* background-color: green; */
  }
  &:nth-child(2) {
    width: 15%;
    text-align: left;
    /* background-color: aqua; */
  }
  &:nth-child(3) {
    width: 30%;
    text-align: left;
    padding-left: 20px;
    /* background-color: yellow; */
  }
  &:nth-child(4) {
    width: 13%;
    text-align: left;
    padding-left: 20px;
    /* background-color: pink; */
  }
  &:nth-child(5) {
    width: 10%;
    text-align: left;
    padding-left: 20px;
    /* background-color: greenyellow; */
  }
  &:nth-child(6) {
    width: 23%;
    padding-left: 20px;
    /* text-align: center; */
    /* background-color: goldenrod; */
  }
  &:nth-child(7) {
    width: 17%;
    text-align: right;
    padding-right: 10px;
    /* background-color: whitesmoke; */
  }
  ${Responsive.laptop} {
    font-size: 12px;
    line-height: 50px;
  }
  ${Responsive.tablet} {
    font-size: 12px;
    line-height: 50px;
  }
  ${Responsive.mobile} {
    display: none;
  }
`;
const VisitRowDiv = styled.div`
  width: 100%;
  /* background-color: green; */
  display: flex;
  align-items: center;
  user-select: none;
  ${Responsive.mobile} {
    position: relative;
    display: unset;
    align-items: unset;
    display: block;
  }
`;
const CancelButton = styled.button`
  align-self: center;
  height: auto;
  background-color: ${Colors.Primary};
  line-height: 35px;
  font-size: 12px;
  border: 0;
  border-radius: 6px;
  padding: 3px 12px;
  color: white;
`;
const VisitColumn = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  margin: unset;
  color: #aaaabe;
  line-height: 70px;
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:nth-child(1) {
    width: 5%;
    text-align: center;
    /* background-color: green; */
    ${Responsive.mobile} {
      display: none;
    }
  }
  &:nth-child(2) {
    width: 15%;
    text-align: left;
    /* background-color: aqua; */
    ${Responsive.mobile} {
      width: 60%;
    }
  }
  &:nth-child(3) {
    width: 30%;
    text-align: left;
    /* background-color: yellow; */
    padding-left: 20px;
    ${Responsive.mobile} {
      display: block;
      width: fit-content;
      padding-left: unset;
    }
  }
  &:nth-child(4) {
    width: 13%;
    text-align: left;
    padding-left: 20px;
    /* background-color: pink; */
    ${Responsive.mobile} {
      display: none;
    }
  }
  &:nth-child(5) {
    width: 10%;
    text-align: left;
    padding-left: 20px;
    /* background-color: greenyellow; */
    ${Responsive.mobile} {
      position: absolute;
      right: 60px;
      top: 55px;
      width: fit-content;
      padding-left: unset;
    }
  }
  &:nth-child(6) {
    width: 23%;
    padding-left: 20px;
    /* text-align: center; */
    /* background-color: goldenrod; */
    ${Responsive.mobile} {
      position: absolute;
      top: 55px;
      right: 0;
      width: 50px;
    }
  }
  &:nth-child(7) {
    width: 17%;
    text-align: right;
    padding-right: 10px;
    /* background-color: whitesmoke; */
    ${Responsive.mobile} {
      width: fit-content;
      position: absolute;
      top: 0;
      right: 0;
      padding-right: unset;
    }
  }

  ${Responsive.laptop} {
    font-size: 14px;
    line-height: 55px;
    height: 55px;
  }
  ${Responsive.tablet} {
    font-size: 14px;
    line-height: 55px;
    height: 55px;
  }
  ${Responsive.mobile} {
    font-size: 14px;
    line-height: 55px;
    height: 55px;
  }
`;
const NoResults = styled.div`
  text-align: center;
  margin-top: 100px;
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  color: ${Colors.Gray};
`;

const DateDiv = styled.div `
  width: 21%;
  display: inline-table;
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
    ${Responsive.laptop} {
    height: 42px;
    width: 21%;
    border-radius: 4px;
    font-size: 13px;

  }
  ${Responsive.tablet} {
    height: 42px;
    width: 21%;
    border-radius: 4px;
    font-size: 13px;
   
  }
 ${Responsive.mobile} {
    height: 38px;
    width: 80%;
    border-radius: 4px;
    font-size: 12px;
    margin-top: 15px;
  }
`;

const isInFuture = (visit: Visit) => dayjs(visit.visitingTime).toDate() >= new Date();

const VisitRow = ({
  visit,
  doctors,
  providers,
  index,
  //cancellable,
  cancelVisitLoading,
  onCancel,
}: {
  visit: Visit;
  doctors: Doctor[];
  providers: ProviderClinic[];
  index: number;
  cancelVisitLoading?: boolean,
  onCancel?: (_: { coreVisitId: string; patientPersonalNumber: string }) => void
}) => {
  const doctor = doctors.find((d: Doctor) => d.personalNumber === visit.doctorPersonalNumber);
  const provider = providers.find(p => p.id == doctor?.providerId);
  return (
    <VisitRowDiv>
      <VisitColumn>{index+1}</VisitColumn>
      <VisitColumn>{doctor?.firstName} {doctor?.lastName}</VisitColumn>
      {/*<VisitColumn>სტომატოლოგი</VisitColumn>*/}
      <VisitColumn>{provider?.name || doctor?.providerName}</VisitColumn>
      <VisitColumn>{dayjs(visit.visitingTime).format('DD.MM.YYYY')}</VisitColumn>
      <VisitColumn>{dayjs(visit.visitingTime).format('HH:mm')}</VisitColumn>
      <VisitColumn>
      {isInFuture(visit) ? 'დაგეგმილი' : 'დასრულებული'}
    {onCancel ? (
      <span style={{marginLeft: 15}}>
        {cancelVisitLoading ? <span>იტვირთება...</span> : (
        <CancelButton onClick={() => onCancel({ coreVisitId: visit.coreVisitId, patientPersonalNumber: visit.patientPersonalNumber })}>გაუქმება</CancelButton>
        )}
      </span>
    ): null}
      </VisitColumn>
    </VisitRowDiv>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function VisitsList() {
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);
  const [showFilters, setShowFilters] = useState(true);
  const switchTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const {
    policies,
    policiesLoading,
    visits,
    visitsLoading,
    visitsHistory = [],
    visitsHistoryLoading,
    cancelVisitLoading,
    doctors,
    doctorsLoading,
    providers,
    providersLoading,
  } = useSelector<RootState, InsuranceState>((data) => data.insurance);

  const dispatch = useAppDispatch();

  const [cancellingCoreVisitId, setCancellingCoreVisitId] = useState<string | undefined>(undefined);

  const [filterDoctorPersonalNumber, setFilterDoctorPersonalNumber] = useState<string | undefined>(undefined);
  const [filterProviderId, setFilterProviderId] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const currentVisits = visits.filter(isInFuture);
  const pastVisits = visits.filter(v => !isInFuture(v));

  const filter = (v: Visit) => {
    if(filterDoctorPersonalNumber && v.doctorPersonalNumber != filterDoctorPersonalNumber){
      // doctor of this visit and filter doesn't match
      return false;
    }
    if(filterProviderId){
      const doctorOfVisit = doctors.find(d => d.personalNumber == v.doctorPersonalNumber);
      if(doctorOfVisit?.providerId.toString() != filterProviderId){
        // provider of this visit and filter doesn't match
        return false;
      }
    }
    return true;
  };

  const currentVisitsFiltered = currentVisits.filter(filter);
  const pastVisitsFiltered = pastVisits.filter(filter);

  useEffect(() => {
    dispatch(getVisits(null));
    if(!doctorsLoading && !doctors.length){
      dispatch(getDoctors(null));
    }
    if(!providersLoading && !providers.length){
      dispatch(getProviders(null));
    }
    // dispatch(getVisitsHistory(null));
  }, []);
  
  return (
    <VisitDoctorContainer className={classes.root}>
      <AppBar position="static">
        <DoctorTabsStyled
          value={activeTab}
          onChange={switchTab}
          aria-label="simple tabs example"
        >
          <DoctorTabStyle label="დაგეგმილი" {...a11yProps(0)} />
          <DoctorTabStyle label="დასრულებული" {...a11yProps(1)} />

          <VisitDoctorTabDiv>
            <VisitDoctorTabSelect
              name="visit-person-name"
              id="visit-person-name"
      >
          <option key="empty" value={''}>
            აირჩიე
    </option>
      {!policiesLoading ? policies.map((p) => (
        <option key={p.policyPolid} value={p.policyPolid}>
        {p.objectName || p.cardNumber}
        </option>
      )) : null}
            </VisitDoctorTabSelect>

            <VisitDoctorTabNewButton to={"/visit/add"}>
              {" "}
              <NewVisitPlusIcon /> ვიზიტის ჩანიშნვა
            </VisitDoctorTabNewButton>

      <VisitDoctorTabHideButton onClick={() => setShowFilters(!showFilters)}>
              {" "}
              <VisitsHideButtonFilterIcon />{" "}
            </VisitDoctorTabHideButton>
          </VisitDoctorTabDiv>
        </DoctorTabsStyled>
      </AppBar>

      {/* selects */}
    {showFilters ? (
        <VisitDoctorSelectContainer>
          {/* top selects */}
          <VisitDoctorTopSelects>
            {/* doctor select */}
        <VisitDoctorTopSelectsGlobal name="SelectDoctor" id="select-doctor" onChange={event => {
          const doctorPersonalNumber = event?.target.value;
          setFilterDoctorPersonalNumber(doctorPersonalNumber);
        }}>
        <option value="">ექიმი</option>
        {uniqBy(visits, 'doctorPersonalNumber').map(v => {
          const doctor = doctors.find(d => d.personalNumber == v.doctorPersonalNumber);
          return doctor ? (
            <option value={doctor.personalNumber}>
              {doctor.firstName} {doctor.lastName}
                </option>
          ) : null;
        })}
            </VisitDoctorTopSelectsGlobal>
            {/* profile select */}
        {/*<VisitDoctorTopSelectsGlobal
              name="SelectProfile"
              id="select-profile"
            >
              <option value="">პროფილი</option>
              <option value="select-profile-value-2">დავით გელეიშვილი</option>
              <option value="select-profile-value-3">ლუკა რამიშვილი</option>
              </VisitDoctorTopSelectsGlobal>*/}
            {/* clinic select */}
            <VisitDoctorTopSelectsGlobal name="SelectClinic" id="select-clinic" onChange={event => {
              const providerId = event?.target.value;
          setFilterProviderId(providerId);
        }}>
              <option value="">კლინიკა</option>
        {uniqBy(visits, 'doctorPersonalNumber').map(v => {
          const doctor = doctors.find(d => d.personalNumber == v.doctorPersonalNumber);
          const provider = providers.find(p => p.id == doctor?.providerId);
          return doctor ? (
            <option value={doctor.providerId}>
              {provider?.name || doctor.providerName}
                </option>
          ) : null;
        })}
            </VisitDoctorTopSelectsGlobal>
            {/* status select */}
            <VisitDoctorTopSelectsGlobal
              name="SelectStatusc"
        id="select-status"
        onChange={event => {
          const tabName = event?.target.value;
          tabName && tabName == 'current' && setActiveTab(0);
          tabName && tabName == 'history' && setActiveTab(1);
          // default to first tab when deselecting this filter
          !tabName && setActiveTab(0);
        }}
            >
              <option value="">სტატუსი</option>
              <option value="current">მიმდინარე</option>
              <option value="history">დასრულებული</option>
            </VisitDoctorTopSelectsGlobal>
          </VisitDoctorTopSelects>

          {/* bottom selects */}
          <VisitDoctorBottomSelects>
          {/* from date select */}

            <DateDiv>
              <DateInput
                  value={startDate}
                  onChange={(value) => setStartDate(value)}
              /> <span> - დან</span>
            </DateDiv>
            <DateDiv>
              <DateInput
                  value={endDate}
                  onChange={(value) => setEndDate(value)}
              /> <span> - მდე</span>
            </DateDiv>
        {/* <VisitDoctorBottomSelectsGlobal
              name="SelectFromDate"
              id="select-from-date"
            >
              <option value="">თარიღი-დან</option>
              <option value="select-frome-date-value-2">
                დავით გელეიშვილი
              </option>
              <option value="select-frome-date-value-3">ლუკა რამიშვილი</option>
              </VisitDoctorBottomSelectsGlobal> */}
        {/* to date select */}

        {/* <VisitDoctorBottomSelectsGlobal
              name="SelectToDate"
              id="select-to-date"
            >
              <option value="">თარიღი-მდე</option>
              <option value="select-to-date-value-2">დავით გელეიშვილი</option>
              <option value="select-to-date-value-3">ლუკა რამიშვილი</option>
              </VisitDoctorBottomSelectsGlobal> */}
          {/* to date select */}
        {/* 
            <VisitDoctorBottomSelectsGlobal
              name="SelectFromClock"
              id="select-from-clock"
            >
              <option value="">საათი-დან</option>
              <option value="select-from-clock-value-2">
                დავით გელეიშვილი
              </option>
              <option value="select-from-clock-value-3">ლუკა რამიშვილი</option>
            </VisitDoctorBottomSelectsGlobal>
            <VisitDoctorBottomSelectsGlobal
              name="SelectToClock"
              id="select-to-clock"
            >
              <option value="">საათი-მდე</option>
              <option value="select-to-clock-value-2">დავით გელეიშვილი</option>
              <option value="select-to-clock-value-3">ლუკა რამიშვილი</option>
            </VisitDoctorBottomSelectsGlobal>
         */}
        {/* <VisitDoctorFilterButton>ფილტრი</VisitDoctorFilterButton> */}
        {/* <VisitDoctorFilterButton onClick={() => clearFilters()}>გასუფთავება</VisitDoctorFilterButton> */}
          </VisitDoctorBottomSelects>
        </VisitDoctorSelectContainer>
      ) : null}
      {/* selects */}

      <GrayLineForDoctors />
      {/* First tab */}
      <TabPanel value={activeTab} index={0}>
        <TabContainer>
          {/* title */}
          <TableHeader>
            <TableHeaderColumn>N</TableHeaderColumn>
            <TableHeaderColumn>ექიმი</TableHeaderColumn>
      {/* <TableHeaderColumn>პროფილი</TableHeaderColumn>*/}
            <TableHeaderColumn>კლინიკა</TableHeaderColumn>
            <TableHeaderColumn>თარიღი</TableHeaderColumn>
            <TableHeaderColumn>საათი</TableHeaderColumn>
            <TableHeaderColumn>სტატუსი</TableHeaderColumn>
          </TableHeader>
      {/* list of current visits */}
    {currentVisitsFiltered.length > 0 ? (
      currentVisitsFiltered.map((v, i) => VisitRow({
        visit: v,
        doctors,
        providers,
        index: i,
        cancelVisitLoading: cancelVisitLoading && cancellingCoreVisitId == v.coreVisitId,
        onCancel: ({ coreVisitId, patientPersonalNumber }: { coreVisitId: string; patientPersonalNumber: string }) => {
          setCancellingCoreVisitId(coreVisitId);
          dispatch(cancelVisit({ coreVisitId, patientPersonalNumber }))
              .then(unwrapResult)
            .then((result) => {
              setCancellingCoreVisitId(undefined);
              if(result.success){
                alert('ვიზიტი გაუქმებულია.');
                dispatch(getVisits(null));
              } else {
                // alert(`მოხდა შეცდომა: ${result.errorMessage}`);
                alert(meaningfulErrorMessage(result, "გთხოვთ, სცადოთ თავიდან."));
              }
            });
        }
      }))
    ) : (
      <NoResults>ინფორმაცია არ მოიძებნა</NoResults>
    )}
        </TabContainer>
      </TabPanel>

      {/* Last Tab */}
      <TabPanel value={activeTab} index={1}>
        <TabContainer>
          {/* title */}
          <TableHeader>
            <TableHeaderColumn>N</TableHeaderColumn>
            <TableHeaderColumn>ექიმი</TableHeaderColumn>
      {/* <TableHeaderColumn>პროფილი</TableHeaderColumn> */}
            <TableHeaderColumn>კლინიკა</TableHeaderColumn>
            <TableHeaderColumn>თარიღი</TableHeaderColumn>
            <TableHeaderColumn>საათი</TableHeaderColumn>
            <TableHeaderColumn>სტატუსი</TableHeaderColumn>
          </TableHeader>
      {/* list of current visits */}
    {pastVisitsFiltered.length > 0 ? (
      pastVisitsFiltered.map((v, i) => VisitRow({
        visit: v, doctors, providers, index: i
      }))
    ) : (
      <NoResults>ინფორმაცია არ მოიძებნა</NoResults>
    )}
        </TabContainer>
      </TabPanel>
    </VisitDoctorContainer>
  );
}
