import React from "react";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import Fonts from "../../styles/Fonts";
// import UpdateArrow from '../../icons/UpdateArrowIcon';
//
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../slices";
import { Claim, Policy, ClaimHistoryItem } from "../../domain";
import {
  getPolicies,
  getClaims,
  getClaimHistory,
} from "../../slices/insuranceSlice";
import { InsuranceState } from "../../domain";
import dayjs from "dayjs";
import UploadedFileList from "../../components/UploadedFileList";

const CurrentPolicyContainer = styled.div`
  width: 100%;
  // min-height: 100vh;
`;
const CurrentPolicyContent = styled.div`
  margin-right: 100px;
  padding-bottom: 100px;
  background-color: #fff;
  border-radius: 10px;
  padding-left: 50px;
  margin-bottom: 100px;
`;
const AppealsNumber = styled.h1`
  font-size: 18px;
  color: ${Colors.TitleBlue};
  margin: unset;
  font-family: ${Fonts.FiraGOMtavruliBold};
  font-weight: 400;
  margin-top: 50px;
`;
const CurrentGrayLine = styled.span`
  width: 100%;
  height: 1px;
  background-color: #f2f5f7;
  display: block;
  margin: 30px 0px;
`;
const CurrentStatusDiv = styled.div``;
const CurrentStatusName = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  color: ${Colors.TitleBlue};
  margin: unset;
  margin-right: 139px;
`;
const CurrentStatusAnswer = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  color: ${Colors.Gray};
  margin: unset;
  font-weight: 400;
`;
const CurrentInfoDiv = styled.div`
  display: flex;
  align-items: center;
`;
const CurrentPolicyLeftNameDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const CurrentPolicyLeftName = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGOMtavruliBold};
  color: ${Colors.TitleBlue};
  margin: unset;
  margin: 15px 0px;
`;
const CurrentPolicyRightNameDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
`;
const CurrentPolicyRightName = styled.h1`
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  color: ${Colors.Gray};
  margin: unset;
  font-weight: 400;
  margin: 15px 0px;
`;
const CurrentUploadFilesText = styled.h1`
  font-size: 18px;
  color: ${Colors.TitleBlue};
  margin: unset;
  font-family: ${Fonts.FiraGOMtavruliBold};
  font-weight: 400;
`;
const Flake = styled.span`
  color: ${Colors.Primary};
`;
const CurrentUploadFiles = styled.h1`
  font-size: 16px;
  color: ${Colors.TitleBlue};
  margin: unset;
  font-family: ${Fonts.FiraGOMtavruliBold};
  font-weight: 400;
  display: block;
  margin-top: 35px;
`;
const CurrentUploadCalculation = styled.h1`
  font-size: 16px;
  color: ${Colors.TitleBlue};
  margin: unset;
  font-family: ${Fonts.FiraGOMtavruliBold};
  font-weight: 400;
  display: block;
  margin-top: 35px;
`;
const CurrentUploadReportInvoice = styled.h1`
  font-size: 16px;
  color: ${Colors.TitleBlue};
  margin: unset;
  font-family: ${Fonts.FiraGOMtavruliBold};
  font-weight: 400;
  display: block;
  margin-top: 35px;
`;
const CurrentUploadConclusion = styled.h1`
  font-size: 16px;
  color: ${Colors.TitleBlue};
  margin: unset;
  font-family: ${Fonts.FiraGOMtavruliBold};
  font-weight: 400;
  display: block;
  margin-top: 35px;
`;
const CurrentButtonDiv = styled.div`
  margin-top: 60px;
  margin-right: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
// const CurrentEditButton = styled.button`
//     width: 200px;
//     height: 50px;
//     background-color: ${Colors.Primary};
//     color: #fff;
//     border-radius: 6px;
//     border: 1px solid ${Colors.Primary};
//     font-size: 14px;
//     font-family: ${Fonts.FiraGOMtavruliBold};
//     font-weight: 400;
//     outline: none;
//         &:focus,
//         &:hover{
//             background-color: ${Colors.HoverRed};
//             outline: none;
//         }
// `;
const CurrentCancelButton = styled.button`
  width: 200px;
  height: 50px;
  background-color: #fff;
  color: ${Colors.Primary};
  border-radius: 6px;
  border: 1px solid ${Colors.Primary};
  font-size: 14px;
  font-family: ${Fonts.FiraGOMtavruliRegular};
  font-weight: 400;
  outline: none;
  margin-left: 20px;
  &:focus,
  &:hover {
    background-color: ${Colors.HoverRed};
    color: #fff;
    outline: none;
  }
`;
// const UpdateArrowIcon = styled(UpdateArrow)`
//     height: 14px;
//     margin-right: 5px;
// `;

const ClaimHistoryDetails: React.FC = () => {
  const {
    policies,
    policiesLoading,
    claims,
    claimsLoading,
    claimHistory = [],
    claimHistoryLoading,
  } = useSelector<RootState, InsuranceState>((data) => data.insurance);

  let { documentNumber } = useParams() as any;

  const claimHistoryItem: ClaimHistoryItem = useSelector<
    RootState,
    ClaimHistoryItem
  >(
    (state) =>
      state.insurance.claimHistory.find(
        (c) => c.documentNumber == documentNumber
      )!
  );

  return (
    <CurrentPolicyContainer>
      <CurrentPolicyContent>
        <AppealsNumber>
          მიმართვა # {claimHistoryItem?.documentNumber}
        </AppealsNumber>

        <CurrentGrayLine />

        <CurrentStatusDiv>
          <CurrentStatusName>სტატუსი</CurrentStatusName>
          <CurrentStatusAnswer>
      {claimHistoryItem?.approved ? 'დარეგულირებული' : 'უარყოფილი'}
          </CurrentStatusAnswer>
        </CurrentStatusDiv>

        <CurrentGrayLine />

        <CurrentInfoDiv>
          <CurrentPolicyLeftNameDiv>
            {/* <CurrentPolicyLeftName>პოლისი</CurrentPolicyLeftName> */}
            {/*         <CurrentPolicyLeftName>დაზღვეული</CurrentPolicyLeftName> */}
            <CurrentPolicyLeftName>სერვისი</CurrentPolicyLeftName>
            <CurrentPolicyLeftName>თანხა</CurrentPolicyLeftName>
            {/* <CurrentPolicyLeftName>მოთხოვნილი თანხა</CurrentPolicyLeftName> */}
          </CurrentPolicyLeftNameDiv>

          <CurrentPolicyRightNameDiv>
            {/* <CurrentPolicyRightName> */}
            {/*           {policy?.objectName || "უცნობი დაზღვეული ობიექტი"} */}
            {/*         </CurrentPolicyRightName> */}
            <CurrentPolicyRightName>
              {claimHistoryItem?.serviceName || "არ არის მითითებული"}
            </CurrentPolicyRightName>
            {/* <CurrentPolicyRightName>{dayjs(claim.createdAt).locale('ka').format('DD MMMM YYYY | HH:mm')}</CurrentPolicyRightName> */}
            <CurrentPolicyRightName>
              {claimHistoryItem?.amount || "არ არის მითითებული"}
            </CurrentPolicyRightName>
          </CurrentPolicyRightNameDiv>
        </CurrentInfoDiv>

        <CurrentGrayLine />

        <CurrentUploadFilesText>ატვირთული დოკუმენტაცია</CurrentUploadFilesText>

        <CurrentUploadFiles>
          <Flake>*</Flake> ბეჭდითა და ხელმოწერით დამოწმებული შესაბამის სატიტულო
          ფურცელზე გამოწერილი ექიმის დანიშნულება (რეცეპტი) ან დაწესებულების
          ბეჭდით, შტამპითა და ხელმოწერით დამოწმებული ფ №IV-100ა
        </CurrentUploadFiles>
        {/* <UploadedFileList files={claimHistoryItem?.medsForm100} /> */}

        <CurrentUploadCalculation>
          <Flake>*</Flake> საფასურის გადახდის დამადასტურებელი სალაროს ჩეკი
        </CurrentUploadCalculation>
        {/* <UploadedFileList files={claim?.calculation} /> */}

        <CurrentUploadReportInvoice>
          <Flake>*</Flake> კალკულაცია (სავალდებულოა სტაციონარული
          მომსახურებეისას, ამბულატორიულიული მომსახურებისას მხოლოდ მანიპულაციის
          ან ამბულატორიული ოპერაციის შემთხვევაში)
        </CurrentUploadReportInvoice>
        {/* <UploadedFileList files={claim?.invoice} /> */}

        <CurrentUploadConclusion>
          კალკულაცია (სავალდებულოა სტაციონარული მომსახურებეისას,
          ამბულატორიულიული მომსახურებისას მხოლოდ მანიპულაციის ან ამბულატორიული
          ოპერაციის შემთხვევაში)
        </CurrentUploadConclusion>
        {/* <UploadedFileList files={claim?.examResults} /> */}

        <CurrentUploadConclusion>
          ანგარიშფაქტურა ( სავალდებულოა სტაციონარული მომსახურებეისას,
          ამბულატორიულიული მომსახურებისას მხოლოდ მანიპულაციის ან ამბულატორიული
          ოპერაციის შემთხვევაში)
        </CurrentUploadConclusion>
        {/* <UploadedFileList files={claim?.examResults} /> */}

        <CurrentUploadConclusion>
          ჩატარებული გამოკვლევების დასკვნები
        </CurrentUploadConclusion>
        {/* <UploadedFileList files={claim?.examResults} /> */}

    {/* <CurrentButtonDiv> */}
          {/* <CurrentEditButton>რედაქტირება</CurrentEditButton> */}
    {/* <CurrentCancelButton>გაუქმება</CurrentCancelButton> */}
    {/* </CurrentButtonDiv> */}
      </CurrentPolicyContent>
    </CurrentPolicyContainer>
  );
};

export default ClaimHistoryDetails;
