import React from "react";
import Navbar from "../Navbar";
import Header from "../Header";
import styled from "styled-components";
import Responsive from "../../config/Responsive";
//import PolicyCard from './PolicyCard';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
// header and content
const CenterSide = styled.main`
  background-color: #fbfbfb;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  padding-right: 100px;
  padding-bottom: 100px;
  padding-left: 50px;
  width: calc(100% - ${Responsive.constants.mainMenuWidth.default}px);
  ${Responsive.fullHD} {
    width: calc(100% - ${Responsive.constants.mainMenuWidth.fullHD}px);
  }
  ${Responsive.desktop} {
    width: calc(100% - ${Responsive.constants.mainMenuWidth.desktop}px);
    padding-left: 50px;
  }
  ${Responsive.screens.NarrowNavBar} {
    width: calc(100% - ${Responsive.constants.mainMenuWidth.NarrowNavBar}px); 
    padding-left: 50px;
  }
  ${Responsive.laptop} {
    padding-left: 30px;
    padding-right: 30px;
    width: calc(100% - ${Responsive.constants.mainMenuWidth.laptop}px);
  }
  ${Responsive.tablet} {
    padding-left: 30px;
    padding-right: 30px;
    margin-left: 75px;
    width: calc(100% - ${Responsive.constants.mainMenuWidth.tabletClosed}px);
  }
  ${Responsive.mobile} {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
`;
// only content
const MainContent = styled.section`
  //  flex: 1;
`;

const Layout: React.FC<{ children: any }> = ({ children }) => {
  return (
    <Wrapper id="app">
      <Navbar />
      <CenterSide>
        <Header
          user={undefined}
          onLogin={() => alert("login")}
          onLogout={() => alert("logout")}
          onCreateAccount={() => alert("register")}
        />
        <MainContent>{children}</MainContent>
      </CenterSide>
    </Wrapper>
  );
};

export default Layout;
