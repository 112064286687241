import * as React from "react";

function ButtonFilter(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 12"
      {...props}
    >
      <path
        data-name="Filter"
        d="M6.87 12h2.26a1.081 1.081 0 100-2.16H6.87a1.081 1.081 0 100 2.16zM0 1.08a1.109 1.109 0 001.13 1.08h13.74a1.081 1.081 0 100-2.16H1.13A1.109 1.109 0 000 1.08zm3.8 6h8.4a1.081 1.081 0 100-2.16H3.8a1.081 1.081 0 100 2.16z"
        fill="#261e56"
      />
    </svg>
  );
}

export default ButtonFilter;
