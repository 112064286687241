import * as React from "react";

function PopUpAcceptedIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 90 90"
      {...props}
    >
      <g data-name="Group 4479">
        <g data-name="Group 4478">
          <g data-name="Group 4475">
            <g data-name="Group 4471">
              <circle
                data-name="Ellipse 191"
                cx={45}
                cy={45}
                r={45}
                fill="#44c2d3"
              />
              <g data-name="Group 871">
                <path
                  data-name="Path 7612"
                  d="M59.826 38.039a1.863 1.863 0 00-2.577-.043l-.045.043L42.57 52.233l-7.143-6.929a1.863 1.863 0 00-2.577.044 1.73 1.73 0 000 2.456l8.432 8.178a1.863 1.863 0 002.577 0l15.922-15.443a1.731 1.731 0 00.045-2.5z"
                  fill="#fff"
                  stroke="#fff"
                  strokeWidth={3}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PopUpAcceptedIcon;