import React from 'react';

function CompensationList(){
    return (
      <div>
        გასაყიდი პოლისების სია
      </div>
    );
}

export default CompensationList;