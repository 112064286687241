import * as React from "react";

function AccordionLeftIcon(props) {
  const width = props.size || props.width || 30;
  const height = width * 30 / 22;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10 9"
      {...props}
    >
      <path
        data-name="Path 9311"
        d="M5.755 9H2.35a3.714 3.714 0 01-.444-.026A2.341 2.341 0 010 6.592V2.298A2.265 2.265 0 011.5.135 2.049 2.049 0 012.171 0H10L8.712 4.277l-.863 2.864a2.991 2.991 0 01-.359.829 2.172 2.172 0 01-1.444 1.009A1.794 1.794 0 015.755 9z"
        fill="#e5e6ed"
      />
    </svg>
  );
}

export default AccordionLeftIcon;
