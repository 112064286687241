import React from 'react'
import CompletedPolicy from "../components/NewForms/CompletedPolicy";

function WarrantyCompletedPolicy() {
    return (
        <div>
            საგარანტიოს დახურული პოლისები
            <CompletedPolicy />
        </div>
    )
}

export default WarrantyCompletedPolicy
